import { Link } from 'react-router-dom';
import { Price } from '../../helpers/PriceHelper';
import { ProductEntity } from '../../entities/ProductEntity';

interface Props {
    product: ProductEntity
}

export function Product(props: Props) {
    function calculateInstallment(price: number) {
        const due = (price * 13.65) / 100; // juros para 6x
        return (Number(price) + Number(due)) / 6;
    }

    const existsStock = props.product.skuList.find(x => x.stock > 0);

    return (
        <div className={`product-item ${existsStock ? "" : "disabled"}`}>
            {existsStock ?
                <Link className="product-link" to={"/produtos/detalhes/" + props.product.url}>
                    <div className="img-wrapper">
                        <img src={props.product.skuList[0].urlImg} alt="" />
                    </div>
                    <div className="product-info">
                        <span className="info-title">{props.product.title.substring(0, 20)}</span>
                        <span className="info-price">{Price(props.product.price)} <p>{Price(props.product.lastPrice)}</p></span>
                        <span className="info-condition">ou 6x {Price(calculateInstallment(props.product.price))}</span>
                    </div>
                </Link>
                :
                <>
                    <div className="stock-zero">
                        <p>ESGOTADO</p>
                    </div>
                    <a className="product-link">
                        <div className="img-wrapper">
                            <img src={props.product.skuList[0].urlImg} alt="" />
                        </div>
                        <div className="product-info">
                            <span className="info-title">{props.product.title.substring(0, 20)}</span>
                            <span className="info-price">{Price(props.product.price)} <p>{Price(props.product.lastPrice)}</p></span>
                            <span className="info-condition">ou 6x {Price(calculateInstallment(props.product.price))}</span>
                        </div>
                    </a>
                </>
            }
        </div>
    );
}