import { useContext, useEffect, useState, KeyboardEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ForgotPasswordService } from '../../services/ForgotPasswordService';
import { SharedContext } from '../../contexts/SharedContext';

interface Props {
    showLoading(value: boolean): void;
}

export function ForgotPassword(props: Props) {
    const sharedContext = useContext(SharedContext);
    const history = useHistory();

    const [showValidFields, setShowValidFields] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordDifferent, setIsPasswordDifferent] = useState(false);
    const [token, setToken] = useState('      ');
    const [step, setStep] = useState(1);
    const [id, setId] = useState('');

    useEffect(() => {
    }, [token]);

    async function initFlow() {
        setShowValidFields(true);
        if (email === '') {
            sharedContext.alert(false, "Informe seu e-mail para continuar.");
            return;
        }

        if (!email.includes("@") || !email.includes(".")) {
            sharedContext.alert(false, "Informe um e-mail válido para continuar.");
            return;
        }

        props.showLoading(true);
        var response = await ForgotPasswordService.getInstance().initFlow(email);
        if (!response.success) {
            sharedContext.alert(false, response.errors[0].message);
            props.showLoading(false);
            return;
        }

        setId(response.data.id);
        setStep(2);
        setShowValidFields(false);

        const firstInput: any = document.querySelector(".token-input");
        firstInput.focus();

        props.showLoading(false);
    }

    async function sendToken() {
        setShowValidFields(true);
        var tokenToSend = token.trim();

        if (tokenToSend.length !== 6) {
            sharedContext.alert(false, "Informe um token válido para continuar.");
            return;
        }

        props.showLoading(true);
        var response = await ForgotPasswordService.getInstance().sendToken(id, tokenToSend);
        if (!response.success) {
            sharedContext.alert(false, response.errors[0].message);
            props.showLoading(false);
            return;
        }

        setStep(3);
        setShowValidFields(false);
        props.showLoading(false);
    }

    async function sendPassword() {
        setShowValidFields(true);
        if (password === '' || passwordConfirmation === '') {
            sharedContext.alert(false, "Informe sua senha e confirme para continuar.");
            return;
        }

        if (password !== passwordConfirmation) {
            setIsPasswordDifferent(true);
            sharedContext.alert(false, "As senhas digitadas não coincidem.");
            return;
        }

        props.showLoading(true);
        var response = await ForgotPasswordService.getInstance().sendPassword(id, password);
        if (!response.success) {
            sharedContext.alert(false, response.errors[0].message);
            props.showLoading(false);
            return;
        }

        setStep(4);
        setShowValidFields(false);
        props.showLoading(false);
    }

    function goToLogin() {
        history.replace("/login");
    }

    function handleToken(value: string, field: number) {
        if (value === '')
            return;

        const fields: any = document.querySelectorAll(".token-input");
        let tokenRef = '';

        for (let i = 0; i < 6; i++) {
            tokenRef += i === field ? value : token.charAt(i);
        }

        setToken(tokenRef.substring(0, 6));

        if (field < fields.length - 1) {
            fields[field + 1].focus();
        }
    }

    function removeToken(event: KeyboardEvent<HTMLInputElement>, field: number) {
        if (event.code !== "Backspace")
            return;

        let tokenRef = '';
        if (token[field] === ' ' && field > 0) {
            const fields: any = document.querySelectorAll(".token-input");
            fields[field - 1].focus();
        }

        for (let i = 0; i < 6; i++) {
            tokenRef += i === field ? ' ' : token.charAt(i);
        }

        setToken(tokenRef);
    }

    function renderStep() {
        switch (step) {
            case 1:
                return <>
                    <div className="title">
                        Esqueceu sua senha?
                    </div>
                    <div className="forgot-passord-info">
                        <span>
                            Insira o seu e-mail e logo em seguida, você receberá instruções de como alterar sua senha em minutos.
                        </span>
                    </div>
                    <div className="form-input">
                        <span>E-mail</span>
                        <div className="input-box">
                            <input
                                className={`input-field light ${showValidFields && email === '' ? 'invalid' : ''}`}
                                type="email"
                                placeholder="O que está procurando?"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-input">
                        <button type="button" className="btn btn-form" onClick={() => initFlow()}>Enviar</button>
                    </div>
                </>
            case 2:
                return <>
                    <div className="title">
                        Informe seu token
                    </div>
                    <div className="forgot-passord-info">
                        <span>
                            Insira o token enviado no seu e-mail, para continuar.
                        </span>
                    </div>
                    <div className="form-input">
                        <div className="token-input-box">
                            <div className="token-input-fields">
                                <input
                                    className={`token-input ${showValidFields && token[0] === '' ? 'invalid' : ''}`}
                                    type="number"
                                    value={token[0]}
                                    onChange={e => handleToken(e.target.value, 0)}
                                    onKeyDown={e => removeToken(e, 0)}
                                />
                                <input
                                    className={`token-input ${showValidFields && token[1] === '' ? 'invalid' : ''}`}
                                    type="number"
                                    value={token[1]}
                                    onChange={e => handleToken(e.target.value, 1)}
                                    onKeyDown={e => removeToken(e, 1)}
                                />
                                <input
                                    className={`token-input ${showValidFields && token[2] === '' ? 'invalid' : ''}`}
                                    type="number"
                                    value={token[2]}
                                    onChange={e => handleToken(e.target.value, 2)}
                                    onKeyDown={e => removeToken(e, 2)}
                                />
                                <input
                                    className={`token-input ${showValidFields && token[3] === '' ? 'invalid' : ''}`}
                                    type="number"
                                    value={token[3]}
                                    onChange={e => handleToken(e.target.value, 3)}
                                    onKeyDown={e => removeToken(e, 3)}
                                />
                                <input
                                    className={`token-input ${showValidFields && token[4] === '' ? 'invalid' : ''}`}
                                    type="number"
                                    value={token[4]}
                                    onChange={e => handleToken(e.target.value, 4)}
                                    onKeyDown={e => removeToken(e, 4)}
                                />
                                <input
                                    className={`token-input ${showValidFields && token[5] === '' ? 'invalid' : ''}`}
                                    type="number"
                                    value={token[5]}
                                    onChange={e => handleToken(e.target.value, 5)}
                                    onKeyDown={e => removeToken(e, 5)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-input">
                        <button type="button" className="btn btn-form" onClick={() => sendToken()}>Enviar</button>
                    </div>
                </>
            case 3:
                return <>
                    <div className="title">
                        Troque sua senha
                    </div>
                    <div className="forgot-passord-info">
                        <span>
                            Informe sua nova senha e confirme para finalizar o processo.
                        </span>
                    </div>
                    <div className="form-input">
                        <span>Senha</span>
                        <div className="input-box">
                            <input
                                className={`input-field light ${isPasswordDifferent || (showValidFields && password === '') ? 'invalid' : ''}`}
                                type={showPassword ? "text" : "password"}
                                placeholder="Senha"
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                            />
                            <button className="input-button btn light" type="button" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <i className="material-icons-outlined">visibility_on</i> : <i className="material-icons-outlined">visibility_off</i>}
                            </button>
                        </div>
                        <div className="input-box">
                            <input
                                className={`input-field light ${isPasswordDifferent || (showValidFields && passwordConfirmation === '') ? 'invalid' : ''}`}
                                type={showPassword ? "text" : "password"}
                                placeholder="Confirmar senha"
                                onChange={e => setPasswordConfirmation(e.target.value)}
                                value={passwordConfirmation}
                            />
                        </div>
                    </div>
                    <div className="form-input">
                        <button type="button" className="btn btn-form" onClick={() => sendPassword()}>Enviar</button>
                    </div>
                </>
            case 4:
                return <>
                    <div className="title">
                        Senha alterada com sucesso!
                    </div>
                    <div className="forgot-passord-info">
                        <span>
                            Agora você já pode entrar utilizando a nova senha.
                        </span>
                    </div>
                    <div className="form-input">
                        <button type="button" className="btn btn-form" onClick={() => goToLogin()}>Ir para o Login</button>
                    </div>
                </>
        }
    }

    return (
        <section className="form-container">
            <div className="form">
                <form className="form-box" action="">
                    <div className="form-content">
                        <div className="logo-box">
                            <div className="logo">
                                <a href="/">
                                    <img src="/assets/images/favicon.png" width="35" height="35" alt="" />
                                    <h1>Cangaço Store</h1>
                                </a>
                            </div>
                        </div>
                        {renderStep()}
                        <div className="form-links">
                            <Link to={"/"}>Voltar para a loja</Link>
                        </div>
                        <div className="form-links">
                            <Link to={"/cadastro"}>É novo por aqui? Crie sua conta.</Link>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
