import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import InputMask from "react-input-mask";
import './checkout-styles.css';
import { UserService } from '../../services/UserService';
import { SharedContext } from '../../contexts/SharedContext';
import { UserContext } from '../../contexts/UserContext';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from 'date-fns/locale/pt-BR';
registerLocale('ptBR', ptBR);

interface Props {
    name: string;
    setName(value: string): void;

    email: string;
    setEmail(value: string): void;

    document: string;
    setDocument(value: string): void;

    birthdayAt: Date;
    setBirthdayAt(value: Date): void;

    phone: string;
    setPhone(value: string): void;

    password: string;
    setPassword(value: string): void;

    passwordConfirmation: string;
    setPasswordConfirmation(value: string): void;

    showLoading(value: boolean): void;
}

export function CheckoutSignup(props: Props) {
    const sharedContext = useContext(SharedContext);
    const userContext = useContext(UserContext);
    const history = useHistory();

    const [showValidFields, setShowValidFields] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordDiferent, setIsPasswordDiferent] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    async function goToAddress() {
        setShowValidFields(true);
        setIsPasswordDiferent(false);
        if (props.name === '' || props.document === '' || props.email === '' ||
            props.phone === '' || props.password === '' || props.passwordConfirmation === '') {
            sharedContext.alert(false, "Preencha todos os campos para fazer o cadastro.");
            return;
        }

        if (props.password !== props.passwordConfirmation) {
            setIsPasswordDiferent(true);
            sharedContext.alert(false, "As senhas digitadas não coincidem.");
            return;
        }

        props.showLoading(true);
        var user = {
            name: props.name,
            document: props.document,
            email: props.email,
            birthdayAt: props.birthdayAt,
            phone: props.phone,
            password: props.password
        };
        var signupResponse = await UserService.getInstance().createUser(user);
        if (!signupResponse.success) {
            sharedContext.alert(false, signupResponse.errors[0].message);
            props.showLoading(false);
            return;
        }

        userContext.handleToken(signupResponse.data.token, signupResponse.data.refreshToken);

        sharedContext.alert(true, "Usuário criado com sucesso!");

        props.setName('');
        props.setEmail('');
        props.setDocument('');
        props.setPhone('');
        props.setPassword('');
        props.setPasswordConfirmation('');

        const myTimeout = setTimeout(() => {
            history.replace("/checkout/endereco");
            window.scrollTo(0, 0);
            props.showLoading(false);

            clearTimeout(myTimeout);
        }, 2000);
    }

    return (
        <form className="form-box" action="">
            <div className="form-content">
                <div className="title">
                    Vamos precisar de mais alguns dados para prosseguir
                </div>
                <div className="form-input">
                    <span>Nome</span>
                    <div className="input-box">
                        <input
                            className={`input-field light ${showValidFields && props.name === '' ? 'invalid' : ''}`}
                            type="text"
                            placeholder="Seu nome completo"
                            value={props.name}
                            onChange={(e) => props.setName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-input">
                    <span>E-mail</span>
                    <div className="input-box">
                        <input
                            className={`input-field light ${showValidFields && props.email === '' ? 'invalid' : ''}`}
                            type="email"
                            placeholder="Digite seu e-mail"
                            value={props.email}
                            onChange={(e) => props.setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-input">
                    <span>CPF</span>
                    <div className="input-box">
                        <InputMask
                            className={`input-field light ${showValidFields && props.document === '' ? 'invalid' : ''}`}
                            type="text"
                            placeholder="Seu CPF"
                            mask="999.999.999-99"
                            value={props.document}
                            onChange={(e) => props.setDocument(e.target.value.replace(/[^a-z0-9]/gi, ''))}
                        />
                    </div>
                </div>
                <div className="form-input">
                    <span>Data de nascimento</span>
                    <div className="input-box">
                        <DatePicker
                            showIcon
                            className={`input-field light ${showValidFields && props.birthdayAt === null ? 'invalid' : ''}`}
                            selected={props.birthdayAt}
                            onChange={(date: Date) => props.setBirthdayAt(date)}
                            placeholderText='Data de nascimento'
                            dateFormat="dd/MM/yyyy"
                            locale="ptBR"
                            icon={<i className="material-icons-outlined">calendar_month</i>}
                        />
                    </div>
                </div>
                <div className="form-input">
                    <span>Celular</span>
                    <div className="input-box">
                        <InputMask
                            className={`input-field light ${showValidFields && props.phone === '' ? 'invalid' : ''}`}
                            type="text"
                            placeholder="Digite seu número"
                            mask="(99) 9 9999-9999"
                            value={props.phone}
                            onChange={(e) => props.setPhone(e.target.value.replace(/[^a-z0-9]/gi, ''))}
                        />
                    </div>
                </div>
                <div className="form-input">
                    <span>Senha</span>
                    <div className="input-box">
                        <input
                            className={`input-field light ${isPasswordDiferent || (showValidFields && props.password === '') ? 'invalid' : ''}`}
                            type={showPassword ? "text" : "password"}
                            placeholder="Digite sua senha"
                            value={props.password}
                            onChange={(e) => props.setPassword(e.target.value)}
                        />
                        <button className="input-button btn light" type="button" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <i className="material-icons-outlined">visibility_on</i> : <i className="material-icons-outlined">visibility_off</i>}
                        </button>
                    </div>
                    <div className="input-box">
                        <input
                            className={`input-field light ${isPasswordDiferent || (showValidFields && props.passwordConfirmation === '') ? 'invalid' : ''}`}
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirmar senha"
                            value={props.passwordConfirmation}
                            onChange={(e) => props.setPasswordConfirmation(e.target.value)}
                        />
                    </div>
                    <p>Ao criar sua conta, você concorda com os nossos Termos de uso e com a Política de privacidade.</p>
                </div>
                <div className="form-input">
                    <button className="btn btn-form" type="button" onClick={() => goToAddress()}>Criar conta</button>
                </div>
                <div className="form-links">
                    <Link to={"/checkout/login"}>Já possui conta?</Link>
                </div>
            </div>
        </form>
    );
}