import { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

export function BannerSlider() {
	const [banners, setBanners] = useState([
		{ 
			id: 1, 
			urlImage: "/assets/images/banners/banner-lisas.png", 
			title: "Essencial e elegante", 
			description: '"Bora" conheçer nossa nova linha de t-shirts lisas!',
			url: "/?colecoes=lisas"
		},
		{ 
			id: 2, 
			urlImage: "/assets/images/banners/banner-plus-size.png"
			, title: "Coleção Plus Size", 
			description: "Celebrando a sua beleza em cada curva – descubra a nova coleção de t-shirts plus size!",
			url: "/?colecoes=plus-size"
		},
		{ 
			id: 3, 
			urlImage: "/assets/images/banners/banner-devertidas.png", 
			title: "Deixe sua personalidade brilhar", 
			description: "Explore nossas t-shirts estampadas com desenhos e animações!",
			url: "/?colecoes=divertidas"
		},
		{ 
			id: 4, 
			urlImage: "/assets/images/banners/banner-listradas.png", 
			title: "Estilo clássico, sempre na moda", 
			description: "Descubra nossa coleção de t-shirts listradas!",
			url: "/?colecoes=listradas"
		},
	]);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [urlBanner, setUrlBanner] = useState('');
	const [bannerTextActive, setBannerTextActive] = useState(false);

	const sliderRef = useRef<Slider>();

	useEffect(() => {
		setBannerText(banners[0]);
	}, []);

	function setBannerText(banner: any) {
		setBannerTextActive(false);
		setTimeout(() => {
			setBannerTextActive(true);
			setTitle(banner.title);
			setDescription(banner.description);
			setUrlBanner(banner.url);
		}, 300);
	}

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		pauseOnFocus: false,
		pauseOnHover: false,
		autoplaySpeed: 5000,
		arrows: false,
		beforeChange: (idCurrent: number, idNext: number) => setBannerText(banners[idNext])
	};

	return (
		<section className="banner">
			<div className="banner-content">
				{sliderRef !== undefined && sliderRef.current !== undefined &&

					<div className="slider-buttons">
						<button className="slider-prev-btn" onClick={() => sliderRef.current.slickNext()}>
							<i className="material-icons-outlined">chevron_right</i>
						</button>
						<button className="slider-next-btn" onClick={() => sliderRef.current.slickPrev()}>
							<i className="material-icons-outlined">chevron_left</i>
						</button>
					</div>
				}
				<div className="banner-opacity"></div>
				<div className="banner-label">
					<Link 
						className={bannerTextActive ? "banner-label-content active" : "banner-label-content"} 
						to={urlBanner}>
						<label className="banner-label-title">{title}</label>
						<label>{description}</label>
						{/* <Link className="btn btn-form" to={"/?colecoes=cangaco"}>Conferir</Link> */}
					</Link>
				</div>
				<Slider {...settings} ref={sliderRef}>
					{banners.map(x => (
						<img key={x.id} src={x.urlImage} alt={`Banner Cangaço - ${x.id}`} />
					))}
				</Slider>
			</div>

		</section>
	);

}