import { Price } from '../../helpers/PriceHelper';
import './checkout-styles.css';
import { CartProductEntity } from '../../entities/CartProductEntity';
import { DiscountCouponEntity } from '../../entities/TransactionEntity';
import { ShippingTypeEntity } from '../../entities/ShippingTypeEntity';

interface Props {
    cartProducts: CartProductEntity[];
    shippingType: ShippingTypeEntity;
    discountCoupon: DiscountCouponEntity;
    subTotal: number;
    total: number;
}

export function CheckoutProducts(props: Props) {
    return (
        <div className="cart-content" >
            {props.cartProducts.length > 0 && <>
                <div className="cart-item-list">
                    {props.cartProducts.map((x) => (
                        <div className="cart-item" key={x.id}>
                            <img src={x.image} alt="" />
                            <div className="cart-item-info">
                                <span className="cart-item-info-top">
                                    {x.count} x {x.title}
                                </span>
                                <span>Cor: {x.color}</span>
                                <span>Tamanho: {x.size}</span>
                                <span>Categoria: {x.category}</span>
                                <span>Marca: {x.brand}</span>
                                <span className="cart-item-info-bottom">
                                    {Price(x.price * x.count)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="cart-footer">
                    <div className="cart-footer-title">
                        <span className="footer-title">Resumo da compra</span>
                    </div>
                    <div className="cart-footer-content">
                        <div className="cart-footer-content-line">
                            <span>Subtotal:</span>
                            <span>{Price(props.subTotal)}</span>
                        </div>
                        <div className="cart-footer-content-line">
                            <span>Frete</span>
                            <span>{Price(props.shippingType != null ? props.shippingType.value : 0)}</span>
                        </div>
                        <div className="cart-footer-content-line">
                            <span>Desconto</span>
                            <span>- {Price(props.discountCoupon != null ? ((props.subTotal * props.discountCoupon.discount) / 100) : 0)}</span>
                        </div>
                        <div className="cart-footer-content-line cart-total">
                            <span>Total</span>
                            <span>{Price(props.total)}</span>
                        </div>
                    </div>
                </div>
            </>}
        </div>
    );
}