import { useEffect } from "react";
import { AppSettings } from "../../settings/AppSettings";
import { maskedStoreCellPhone } from "../../helpers/PhoneHelper";

export function FrequentQuestions() {
    useEffect(() => {
        window.scroll(0, 0);
    });
    return (
        <div className="information-screen">
            <div className="information-content">
                <h1>Perguntas Frequentes (FAQ)</h1>

                <h2>Pedidos e Compras</h2>

                <h3>Como faço um pedido?</h3>
                <p>Para fazer um pedido, navegue pelo nosso catálogo de produtos, adicione os itens desejados ao carrinho e siga as instruções de finalização da compra.</p>

                <h3>Quais métodos de pagamento são aceitos?</h3>
                <p>Aceitamos os seguintes métodos de pagamento: pagamento na entrega, boleto, pix, cartões de crédito (Visa, elo, MasterCard, American Express).</p>

                <h3>Posso alterar ou cancelar meu pedido?</h3>
                <p>Se precisar alterar ou cancelar seu pedido, entre em contato conosco o mais rápido possível através do whatsapp {maskedStoreCellPhone()} ou por e-mail {AppSettings.StoreEmailAddress}. Faremos o possível para atender à sua solicitação.</p>

                <h2>Envio e Entrega</h2>

                <h3>Quais são as opções de envio?</h3>
                <p>Oferecemos várias opções de envio, incluindo envio padrão, expresso e prioritário. As opções disponíveis podem variar de acordo com a sua localização.</p>

                <h3>Quanto tempo leva para meu pedido ser entregue?</h3>
                <p>O tempo de entrega depende da opção de envio escolhida e da sua localização. Envio padrão geralmente leva de 5 a 10 dias úteis, enquanto envio expresso pode levar de 2 a 5 dias úteis.</p>

                <h3>Como posso rastrear meu pedido?</h3>
                <p>Após o envio do seu pedido, você receberá um e-mail com o número de rastreamento e instruções sobre como acompanhar a entrega.</p>

                <h2>Devoluções e Reembolsos</h2>

                <h3>Como faço para devolver um produto?</h3>
                <p>Para devolver um produto, siga as instruções detalhadas na nossa <a href="/politica-de-reembolso">Política de Reembolso</a>. Certifique-se de que o produto esteja em sua embalagem original e não tenha sido usado ou danificado.</p>

                <h3>Quanto tempo leva para processar um reembolso?</h3>
                <p>Após recebermos o produto devolvido, inspecionaremos e processaremos o reembolso em até 10 dias úteis. O crédito será aplicado automaticamente ao seu cartão de crédito ou método original de pagamento.</p>

                <h3>Posso trocar um produto?</h3>
                <p>Sim, trocamos produtos defeituosos ou danificados. Para solicitar uma troca, entre em contato conosco pelo e-mail {AppSettings.StoreEmailAddress} com os detalhes do problema.</p>

                <h2>Conta e Configurações</h2>

                <h3>Como crio uma conta?</h3>
                <p>Para criar uma conta, clique em "Entrar | Cadastrar" no canto superior direito do site ou no menu, e preencha as informações solicitadas.</p>

                <h3>Esqueci minha senha. O que devo fazer?</h3>
                <p>Se você esqueceu sua senha, clique em "Esqueci minha senha" na página de login e siga as instruções para redefini-la.</p>

                {/* <h3>Como posso atualizar minhas informações de conta?</h3>
                <p>Para atualizar suas informações de conta, faça login e vá para "Minha Conta". Lá, você poderá editar suas informações pessoais, endereço de entrega e métodos de pagamento.</p> */}

                <h2>Contato</h2>

                <h3>Como posso entrar em contato com o suporte ao cliente?</h3>
                <p>Se você tiver alguma dúvida, entre em contato conosco em:</p>
                <p>
                    Cangaço Store<br />
                    {AppSettings.StoreEmailAddress}<br />{
                        maskedStoreCellPhone()}</p>
            </div>
        </div>
    );
}