import './loading.css';

interface Props {
    showLoading: boolean;
}

export function Loading(props: Props) {
    return (
        props.showLoading &&
        <div className="loading">
            <div className="spinner">
                {/* <img src="/assets/images/loading.png" height={70} alt="" /> */}
                <img src="/assets/images/lampiao.png" height={70} alt="" />
            </div>
        </div>
    );
}