import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Header } from './components/header/Header';
import { Footer } from './components/footer/Footer';
import { Loading } from './components/loading/Loading';
import { Maintenance } from './pages/maintenance/Maintenance';
import { Products } from './pages/products/Products';
import { ProductDetails } from './pages/products/ProductDetails';
import { Checkout } from './pages/checkout/Checkout';
import { Login } from './pages/user/Login';
import { DomainService } from './services/DomainService';
import { Contact } from './pages/contact/Contact';
import { Signup } from './pages/user/Signup';
import { ForgotPassword } from './pages/user/ForgotPassword';
import { AccountTransaction } from './pages/user/AccountTransaction';
import { BannerSlider } from './components/banner/BannerSlider';
import { AppSettings } from './settings/AppSettings';
import { SharedContextProvider } from './contexts/SharedContext';
import { CartContextProvider } from './contexts/CartContext';
import { UserContextProvider } from './contexts/UserContext';
import { PrivacyPolicies } from './pages/privacyPolicies/PrivacyPolicies';
import { RefoundPolicies } from './pages/refoundPolicies/RefoundPolicies';
import { FrequentQuestions } from './pages/frequentQuestions/FrequentQuestions';
import { Shipping } from './pages/shipping/Shipping';

declare global {
    interface Window {
        MercadoPago?: any;
        JSConfetti?: any;
    }
}

export function App() {
    const [topSellingProducts, setTopSellingProducts] = useState([]);
    const [newProducts, setNewProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [collections, setCollections] = useState([]);

    const [loading, setLoading] = useState(false);
    const [noScroll, setNoScroll] = useState(false);

    useEffect(() => {
        getContent();
    }, []);

    async function getContent() {
        const domainService = DomainService.getInstance();
        var categoryResult = await domainService.getCategories();
        if (categoryResult.success)
            setCategories(categoryResult.data);

        var brandResult = await domainService.getBrands();
        if (brandResult.success)
            setBrands(brandResult.data);

        var collectionResult = await domainService.getCollections();
        if (collectionResult.success)
            setCollections(collectionResult.data);

        setLoading(false);
    }

    function handleNoScroll(value: boolean) {
        const bodyDocument = document.querySelector("body");
        if (value) bodyDocument.classList.add("no-scroll");
        else bodyDocument.classList.remove("no-scroll");

        setNoScroll(value);
    }

    const inMaintenance = AppSettings.AppInMaintenance;
    if (inMaintenance) return (<Maintenance></Maintenance>);
    return (
        <div className="layout">
            <SharedContextProvider>
                <CartContextProvider>
                    <UserContextProvider>
                        <BrowserRouter>
                            <Loading showLoading={loading}></Loading>
                            <Switch>
                                <Route path="/login" exact render={(props) =>
                                    <Login showLoading={(bool: boolean) => setLoading(bool)} {...props}></Login>}
                                />
                                <Route path="/cadastro" exact >
                                    <Signup showLoading={(bool: boolean) => setLoading(bool)}></Signup>
                                </Route>
                                <Route exact path="/esqueci-minha-senha" render={(props) =>
                                    <ForgotPassword showLoading={(bool: boolean) => setLoading(bool)} {...props} />}
                                />
                                <Route exact path="/checkout/:step" render={(props) =>
                                    <Checkout showLoading={(bool: boolean) => setLoading(bool)} {...props} />}
                                />
                                <>
                                    <Header
                                        categories={categories}
                                        brands={brands}
                                        collections={collections}
                                        setNoScroll={(bool: boolean) => handleNoScroll(bool)}
                                        showLoading={(bool: boolean) => setLoading(bool)}
                                    />
                                    <Route path="/" exact render={props => (
                                        <>
                                            <BannerSlider />
                                            <Products
                                                showLoading={(bool: boolean) => setLoading(bool)}
                                                categories={categories}
                                                brands={brands}
                                                collections={collections}
                                                {...props}
                                            />
                                        </>
                                    )} />
                                    <Route path="/produtos" render={props => (
                                        <>
                                            <Route path={props.match.url + "/detalhes/:productUrl"} render={props => (
                                                <ProductDetails
                                                    showLoading={(bool: boolean) => setLoading(bool)}
                                                    setNoScroll={(bool: boolean) => handleNoScroll(bool)}
                                                    productUrl={props.match.params.productUrl}
                                                />
                                            )} />
                                        </>
                                    )} />
                                    <Route path="/contato" exact >
                                        <Contact showLoading={(bool: boolean) => setLoading(bool)}></Contact>
                                    </Route>
                                    <Route path="/minha-conta/pedidos" render={(props) =>
                                        <AccountTransaction
                                            showLoading={(bool: boolean) => setLoading(bool)}
                                            categories={categories}
                                            brands={brands}
                                            collections={collections}
                                            {...props}
                                        />}
                                    />
                                    <Route path="/politica-de-reembolso" exact >
                                        <RefoundPolicies />
                                    </Route>
                                    <Route path="/politica-de-privacidade" exact >
                                        <PrivacyPolicies />
                                    </Route>
                                    <Route path="/perguntas-frequentes" exact >
                                        <FrequentQuestions />
                                    </Route>
                                    <Route path="/frete" exact >
                                        <Shipping />
                                    </Route>
                                    <Footer categories={categories} showLoading={(bool: boolean) => setLoading(bool)}></Footer>
                                </>
                            </Switch>
                        </BrowserRouter>
                    </UserContextProvider>
                </CartContextProvider>
            </SharedContextProvider>
        </div>
    );
}