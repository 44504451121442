import { LoginResponse } from '../contracts/UserContracts';
import { BaseResponse } from '../entities/BaseResponse';
import { BaseService } from './BaseService';

export class UserService extends BaseService {
    static myInstance: UserService = null;

    static getInstance() {
        if (UserService.myInstance === null) {
            UserService.myInstance = new UserService();
        }

        return this.myInstance;
    }

    async checkEmail(email: string): Promise<BaseResponse<any>> {
        return await super.get("/user/checkemail?email=" + email);
    }

    async createUser(request: object): Promise<BaseResponse<LoginResponse>> {
        return await super.post("/user/createuser", request);
    }

    async createAddress(request: object): Promise<BaseResponse<any>> {
        return await super.post("/user/createaddress", request);
    }

    async updateAddress(request: object): Promise<BaseResponse<any>> {
        return await super.post("/user/updateaddress", request);
    }

    async activateAddress(addressId: string): Promise<BaseResponse<any>> {
        return await super.post("/user/activateaddress/" + addressId);
    }

    async signup(email: string): Promise<BaseResponse<any>> {
        return await super.post("/user/signupnewsletter", { email: email });
    }
}