import { createContext, useEffect, useState } from 'react';
import { CartProductEntity } from '../entities/CartProductEntity';
import { toast, Toaster } from 'react-hot-toast';
import { ShippingTypeEntity } from '../entities/ShippingTypeEntity';
import { AppSettings } from '../settings/AppSettings';
import { DiscountCouponEntity } from '../entities/TransactionEntity';

export type CartContextType = {
    cartProducts: CartProductEntity[];
    total: number;
    subTotal: number;
    discountCoupon: DiscountCouponEntity;
    shippingType: ShippingTypeEntity;

    // cart
    addCartProduct(value: CartProductEntity): void;
    removeCartProduct(value: CartProductEntity): void;
    updateCartProducts(value: Array<CartProductEntity>): void;
    getTotalCartProducts: () => number;
    setDiscountCoupon(value: DiscountCouponEntity): void;

    // shipping
    setShippingType(value: ShippingTypeEntity): void;
};

export const CartContext = createContext<CartContextType | null>(null);

interface Props {
    children: any
}

export function CartContextProvider(props: Props) {
    // cart data
    const [cartProducts, setCartProducts] = useState<CartProductEntity[]>([]);
    const [discountCoupon, setDiscountCoupon] = useState<DiscountCouponEntity>(null);

    // selling data
    const [total, setTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [shippingType, setShippingType] = useState(null);

    useEffect(() => {
        var cartProductList = JSON.parse(localStorage.getItem(AppSettings.LocalStorageCartVarName));
        if (cartProductList === null)
            cartProductList = [];

        setCartProducts(cartProductList);
        checkValues();
    }, [discountCoupon, shippingType, cartProducts.length > 0]);

    function addCartProduct(value: CartProductEntity): void {
        var cartProductList = cartProducts;
        if (cartProductList === null)
            cartProductList = [];

        // verify if sku product are in list
        if (cartProductList.filter((x: CartProductEntity) => x.url === value.url && x.color === value.color && x.size === value.size).length > 0) {
            cartProductList.map((x: CartProductEntity) => {
                if (x.url === value.url && x.color === value.color && x.size === value.size)
                    if (x.count + value.count > value.stock)
                        x.count = value.stock;
                    else
                        x.count += value.count;
            });

            updateCartProducts(cartProductList);
        } else {
            cartProductList.push(value);
            updateCartProducts(cartProductList);
        }

        toast.success(`${value.title} adicionado no carrinho.`);
    }

    function removeCartProduct(value: CartProductEntity): void {
        const newProducts = cartProducts.slice();
        const index = newProducts.findIndex(x => x.url === value.url && x.color === value.color && x.size === value.size);
        newProducts.splice(index, 1);

        updateCartProducts(newProducts);

        toast.success(`${value.title} removido no carrinho.`);
    }

    function updateCartProducts(products: Array<CartProductEntity>): void {
        if (products === undefined || products === null)
            products = [];

        if (products.length === 0) {

            setDiscountCoupon(null);
        }

        setCartProducts(products);
        checkValues();
        localStorage.setItem(AppSettings.LocalStorageCartVarName, JSON.stringify(products));
    }

    function getTotalCartProducts(): number {
        var totalCount = 0;
        cartProducts.map((x: CartProductEntity) => {
            totalCount = totalCount + x.count
        });

        return totalCount;
    }

    function checkValues(): void {
        var subTotal = 0;
        var total = 0;

        // counting product values
        cartProducts.forEach((x: CartProductEntity) => {
            subTotal = subTotal + (x.count * x.price)
        });

        total = subTotal;

        // adding discount coupon if exists
        if (discountCoupon)
            total = total - ((Number(discountCoupon.discount) * Number(total)) / 100)

        // adding shippingType value if exists
        if (shippingType && shippingType.value > 0)
            total = total + shippingType.value;

        setSubTotal(subTotal);
        setTotal(total);
    }

    return (
        <CartContext.Provider
            value={{
                cartProducts, discountCoupon, total, subTotal, shippingType,

                // cart
                addCartProduct, removeCartProduct, updateCartProducts, getTotalCartProducts, setDiscountCoupon,

                // shipping
                setShippingType,
            }}
        >
            <div><Toaster /></div>
            {props.children}
        </CartContext.Provider>
    );
}