export interface Error {
    code: string;
    message: string;
}

export interface BaseResponse<T> {
    success: boolean;
    data: T
    errors: Error[]
}

export class BaseResponse<T> implements BaseResponse<T> {
    success: boolean;
    data: T
    errors: Error[]

    constructor(success: boolean, data: T, errors: Error[]){
        this.success = success;
        this.data = data;
        this.errors = errors;
    }
}