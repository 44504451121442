import { useEffect } from "react";
import { AppSettings } from "../../settings/AppSettings";
import { maskedStoreCellPhone } from "../../helpers/PhoneHelper";

export function RefoundPolicies() {
    useEffect(() => {
        window.scroll(0, 0);
    });
    return (
        <div className="information-screen">
            <div className="information-content">
                <h1>Política de Reembolso</h1>

                <h2>Introdução</h2>
                <p>Na <strong>Cangaço Store</strong>, nossa prioridade é garantir sua satisfação com suas compras. Se por algum motivo você não estiver satisfeito com seu pedido, estamos aqui para ajudar. Por favor, leia nossa Política de Reembolso cuidadosamente para entender nossos procedimentos e critérios.</p>

                <h2>Requisitos para Reembolso</h2>
                <p>Para ser elegível para um reembolso, certifique-se de que:</p>
                <ul>
                    <li>O produto foi comprado nos últimos 30 dias</li>
                    <li>O produto está em sua embalagem original</li>
                    <li>O produto não foi usado ou danificado</li>
                    <li>Você tem o recibo ou comprovante de compra</li>
                </ul>

                <h2>Processo de Reembolso</h2>
                <p>Para iniciar um reembolso, siga os seguintes passos:</p>
                <ol>
                    <li>Envie um e-mail para <strong>{AppSettings.StoreEmailAddress}</strong> com o número do pedido e a descrição do problema.</li>
                    <li>Nossa equipe de atendimento ao cliente analisará sua solicitação e entrará em contato para fornecer instruções adicionais.</li>
                    <li>Envie o produto de volta para o endereço que será fornecido pela nossa equipe.</li>
                </ol>
                <p>Por favor, não envie o produto de volta sem primeiro entrar em contato conosco.</p>

                <h2>Custos de Envio</h2>
                <p>Os custos de envio para devolução do produto serão de responsabilidade do cliente. No entanto, se o reembolso for aprovado devido a um erro nosso (como envio de produto errado ou defeituoso), reembolsaremos os custos de envio.</p>

                <h2>Produtos Não Elegíveis para Reembolso</h2>
                <p>Alguns itens não podem ser reembolsados, incluindo:</p>
                <ul>
                    <li>Cartões de presente</li>
                    <li>Produtos em promoção ou liquidação</li>
                    <li>Produtos personalizados</li>
                    <li>Itens de higiene pessoal (por exemplo, brincos, roupas íntimas)</li>
                </ul>

                <h2>Processamento de Reembolsos</h2>
                <p>Após receber e inspecionar o produto devolvido, enviaremos um e-mail para notificá-lo sobre a aprovação ou rejeição do seu reembolso. Se aprovado, o reembolso será processado e um crédito será aplicado automaticamente ao seu cartão de crédito ou método original de pagamento, dentro de um determinado número de dias.</p>

                <h2>Trocas</h2>
                <p>Somente substituímos produtos se estiverem defeituosos ou danificados. Se você precisar trocá-lo pelo mesmo produto, envie um e-mail para <strong>{AppSettings.StoreEmailAddress}</strong> e envie o produto para o endereço que será fornecido pela nossa equipe.</p>

                <h2>Contato</h2>
                <p>Se você tiver alguma dúvida sobre nossa Política de Reembolso, entre em contato conosco em:</p>
                <p>
                    Cangaço Store<br />
                    {AppSettings.StoreEmailAddress}<br />{
                        maskedStoreCellPhone()}</p>
            </div>
        </div>
    );
}