import { CreateCardTokenRequest, CreateCardTokenResponse, GetInstallmentsRequest, GetInstallmentsResponse, GetPaymentMethodsResponse, IdentificationType } from '../contracts/MercadoPagoContracts';
import { BaseResponse } from '../entities/BaseResponse';
import { AppSettings } from '../settings/AppSettings';
import { BaseService } from './BaseService';

export class MercadoPagoService extends BaseService {
    static myInstance: MercadoPagoService = null;
    private partnerInstance: any;

    static getInstance() {
        if (this.myInstance === null) {
            this.myInstance = new MercadoPagoService();
            this.myInstance.partnerInstance = new window.MercadoPago(AppSettings.MercadoPagoPublicKey);
        }

        return this.myInstance;
    }

    async getIdentificationTypes(): Promise<BaseResponse<IdentificationType[]>> {
        try {
            var response = await this.partnerInstance.getIdentificationTypes();
            return new BaseResponse(true, response, null);
        } catch (err) {
            console.log(err);
            console.log("Erro ao obter os ids do mercado pago.");
            return new BaseResponse(false, null, null);
        }
    }

    async getPaymentMethods(bin: string): Promise<BaseResponse<GetPaymentMethodsResponse>> {
        try {
            var response = await this.partnerInstance.getPaymentMethods({ bin: bin });
            return new BaseResponse(response.results[0] !== undefined, response.results[0], null);
        } catch (err) {
            console.log(err);
            console.log("Não foi possível encontrar os dados para o cartão informado, favor insira novamente os dados.");
            return new BaseResponse(false, null, null);
        }
    }

    async getInstallments(data: GetInstallmentsRequest): Promise<BaseResponse<GetInstallmentsResponse>> {
        try {
            var response = await this.partnerInstance.getInstallments(data);
            return new BaseResponse(true, response[0], null);
        } catch (err) {
            console.log(err);
            console.log("Não foi possível encontrar os dados para o cartão informado, favor insira novamente os dados.");
            return new BaseResponse(false, null, null);
        }
    }

    async createCardToken(cardData: CreateCardTokenRequest): Promise<BaseResponse<CreateCardTokenResponse>> {
        try {
            var response = await this.partnerInstance.createCardToken(cardData);
            return new BaseResponse(true, response, null);
        } catch (err) {
            console.log(err);
            console.log("Erro ao obter dados do cartão informado.");
            return new BaseResponse(false, null, null);
        }
    }
}