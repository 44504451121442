import { createContext, useEffect, useState } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { ShippingTypeEntity } from '../entities/ShippingTypeEntity';
import { DomainService } from '../services/DomainService';

export type SharedContextType = {
    showCart: boolean,
    showFilterButton: boolean,
    showSearchButton: boolean,
    shippingTypeList: ShippingTypeEntity[],
    setShowCart(value: boolean): void,
    setShowFilterButton(value: boolean): void,
    setShowSearchButton(value: boolean): void,
    setShippingTypeList(value: ShippingTypeEntity[]): void,
    checkShipping(value: string): Promise<void>, 
    alert(success: boolean, value: string): void;
};

export const SharedContext = createContext<SharedContextType | null>(null);

interface Props {
    children: any
}

export function SharedContextProvider(props: Props) {
    const [showCart, setShowCart] = useState(false);
    const [showFilterButton, setShowFilterButton] = useState(false);
    const [showSearchButton, setShowSearchButton] = useState(false);
    const [shippingTypeList, setShippingTypeList] = useState<ShippingTypeEntity[]>([]);

    useEffect(() => {
        // ...
    }, []);

    async function checkShipping(zipCode: string): Promise<void> {
        var response = await DomainService.getInstance().checkShipping(zipCode);
        if (!response.success)
            return;

        setShippingTypeList(response.data);
        // setShippingType(null);
    }

    function alert(success: boolean, value: string): void {
        if (success) toast.success(value);
        else toast.error(value);
    }

    return (
        <SharedContext.Provider value={{
            // state
            showCart, showFilterButton, showSearchButton, shippingTypeList,

            // methods
            setShowCart, setShowFilterButton, setShowSearchButton, setShippingTypeList, checkShipping, alert
        }} >
            <div><Toaster /></div>
            {props.children}
        </SharedContext.Provider>
    );
}