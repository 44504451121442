import { BaseService } from './BaseService';

export class ForgotPasswordService extends BaseService {
    static myInstance: ForgotPasswordService = null;

    static getInstance() {
        if (ForgotPasswordService.myInstance === null) {
            ForgotPasswordService.myInstance = new ForgotPasswordService();
        }

        return this.myInstance;
    }

    async initFlow(email: string) {
        return await super.get("/forgotpassword/init?email=" + email);
    }

    async sendToken(id: string, token: string) {
        return await super.post("/forgotpassword/token", { id: id, token: token });
    }

    async sendPassword(id: string, password: string) {
        return await super.post("/forgotpassword/change", { id: id, password: password });
    }
}