import { useContext, useEffect, useState } from 'react';
import { TransactionService } from '../../services/TransactionService';
import { TransactionStatus } from '../../enums/TransactionStatus';
import { PaymentType } from '../../enums/PaymentType';
import { Price } from '../../helpers/PriceHelper';
import { useHistory } from 'react-router-dom';
import { TransactionEntity } from '../../entities/TransactionEntity';
import { BrandEntity, CategoryEntity, CollectionEntity } from '../../entities/DomainEntity';
import { UserContext } from '../../contexts/UserContext';

interface Props {
    showLoading(value: boolean): void;
    categories: CategoryEntity[];
    brands: BrandEntity[];
    collections: CollectionEntity[];
}

export function AccountTransaction(props: Props) {
    const userContext = useContext(UserContext);
    const history = useHistory();
    const [transactions, setTransactions] = useState<TransactionEntity[]>(null);

    useEffect(() => {
        const bodyDocument: any = document.querySelector("body");
        bodyDocument.classList = [];

        props.showLoading(true);
        const timeOut = setInterval(() => {
            if (userContext.token === '') {
                history.replace("/login");
                props.showLoading(false);
                return;
            }

            getTransactions();
            clearInterval(timeOut);
        }, 1000);

        return () => {
            setTransactions(null);
        };
    }, []);

    async function getTransactions() {
        var transactionsListResponse = await TransactionService.getInstance().getTransactions();
        if (transactionsListResponse.success)
            setTransactions(transactionsListResponse.data);

        props.showLoading(false);
    }

    return (
        <div className="user-transaction-list">
            <div className="user-transaction-content">
                {transactions !== null && transactions.length > 0 ? transactions.map(x => {
                    var data = new Date(x.createdAt);
                    var day = data.getDate() < 10 ? `0${data.getDate()}` : data.getDate();
                    var month = data.getMonth() < 10 ? `0${data.getMonth()}` : data.getMonth();
                    var hour = data.getHours() < 10 ? `0${data.getHours()}` : data.getHours();
                    var minute = data.getMinutes() < 10 ? `0${data.getMinutes()}` : data.getMinutes();

                    return (
                        <div className="user-transaction-item" key={x.id}>
                            <div className="transaction-item-title">
                                <label><strong>Pedido: {x.id}</strong></label>
                                <label>{`${day}/${month}/${data.getFullYear().toString().substring(2)} ${hour}:${minute}`}</label>
                            </div>
                            <div className="transaction-summary">
                                <div className="transaction-line">
                                    <label>Subtotal: </label>
                                    <label>{Price(x.subTotalValue)}</label>
                                </div>
                                <div className="transaction-line">
                                    <label>Desconto: </label>
                                    <label>- {Price(x.discountValue)}</label>
                                </div>
                                <div className="transaction-line">
                                    <label>Frete: </label>
                                    <label>{Price(x.shippingValue)}</label>
                                </div>
                                <div className="transaction-line">
                                    <label>Valor Pago: </label>
                                    <label>{Price(x.totalValue)}</label>
                                </div>
                                <div className="transaction-line">
                                    <label>Forma de pagamento: </label>
                                    <label>{x.paymentType === PaymentType.PaymentOnTakeOff && "Pagamento na entrega"}</label>
                                    <label>{x.paymentType === PaymentType.PixTransfer && "PIX"}</label>
                                    <label>{x.paymentType === PaymentType.CreditCard && "Cartão"}</label>
                                    <label>{x.paymentType === PaymentType.BankTicket && "Boleto"}</label>
                                </div>
                                <div className="transaction-line">
                                    <label>Status: </label>
                                    <label>{x.transactionStatus === TransactionStatus.Created && "Criado"}</label>
                                    <label>{x.transactionStatus === TransactionStatus.WatingPayment && "Aguardando pagamento"}</label>
                                    <label>{x.transactionStatus === TransactionStatus.PaymentConfirmed && "Pagamento confirmado"}</label>
                                    <label>{x.transactionStatus === TransactionStatus.Sended && "Enviado"}</label>
                                    <label>{x.transactionStatus === TransactionStatus.WatingTaking && "Aguardando retirada"}</label>
                                    <label>{x.transactionStatus === TransactionStatus.Finished && "Finalizado"}</label>
                                    <label>{x.transactionStatus === TransactionStatus.Cancelled && "Cancelado"}</label>
                                </div>
                                <div className="transaction-line">
                                    <label>Quantidade de produtos: </label>
                                    <label>{x.transactionItemList.reduce((accumulator, object: any) => accumulator + object.count, 0)}</label>
                                </div>
                            </div>
                        </div>
                    )
                }) :
                    <div className="list-empty">
                        <div className="product-list-empty-info">
                            <span>
                                Você ainda não possui nenhum pedido!
                            </span>
                            <i className="material-icons-outlined">sentiment_dissatisfied</i>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
