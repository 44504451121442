import { useContext, useEffect } from 'react';
import { PaymentType } from '../../enums/PaymentType';
import { CheckoutProducts } from './CheckoutProducts';
import { Price } from '../../helpers/PriceHelper';
import { CreateTransactionSuccessData } from '../../contracts/SharedContracts';
import './checkout-styles.css';
import { SharedContext } from '../../contexts/SharedContext';

interface Props {
    successData: CreateTransactionSuccessData;
}

export function CheckoutSuccess(props: Props) {
    const sharedContext = useContext(SharedContext);
    
    useEffect(() => {
        window.scroll(0, 0);
        generateConfetti();
    }, []);

    function generateConfetti() {
        const jsConfetti = new window.JSConfetti();
        jsConfetti.addConfetti();
    }

    async function copyPixCode() {
        copyText(props.successData.paymentResponse.paymentData.qrCode);
        sharedContext.alert(true, `Chave PIX ${props.successData.paymentResponse.paymentData.qrCode.substring(0, 20)}... copiada com sucesso!`);
    }

    async function copyBarCode() {
        copyText(props.successData.paymentResponse.paymentData.barcode);
        sharedContext.alert(true, `Código de barras ${props.successData.paymentResponse.paymentData.barcode.substring(0, 20)}... copiado com sucesso!`);
    }

    function copyText(text: string) {
        var clipBoardElem = document.createElement("input");
        document.body.appendChild(clipBoardElem);
        clipBoardElem.value = text;
        clipBoardElem.select();
        var successfulCopy = document.execCommand('copy');
        document.body.removeChild(clipBoardElem);
    }

    return (
        props.successData &&
        <div className="checkout-wrap">
            <div className="checkout-column">
                {props.successData.paymentResponse.paymentData?.bankStatus === "in_process" ||
                    props.successData.paymentResponse.paymentData?.bankStatus === "in_mediation" ?
                    <div className="checkout-success">
                        <div className="checkout-title">
                            <i className="material-icons-outlined" onClick={() => generateConfetti()} style={{ color: "#fdc55a" }}>schedule</i>
                            <h3>ESTAMOS PROCESSANDO SEU PAGAMENTO</h3>
                            <h3>Seu Pedido: {props.successData.paymentResponse.id}</h3>
                        </div>
                        <div className="checkout-payment">
                            <div className="checkout-in-analise">
                                <p>
                                    Enquanto seu pagamento está em análise, você pode continuar vendo outros produtos, ou se preferir poderá acompanhar o processo em
                                </p>
                                <p><b>Meus Pedidos</b></p>
                                <div className="check-status">
                                    <a className="btn btn-form text-icon" href={"/minha-conta/pedidos"}>
                                        Meus Pedidos <i className="material-icons-outlined">arrow_forward</i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="back-to-site">
                            <a href="/">
                                <i className="material-icons-outlined">arrow_backward</i>
                                <p>Voltar para o site</p>
                            </a>
                        </div>
                    </div>
                    :
                    <div className="checkout-success">
                        <div className="checkout-title">
                            <i className="material-icons-outlined" onClick={() => generateConfetti()}>check_circle</i>
                            <h3>PARABÉNS PELA SUA COMPRA</h3>
                            <h3>Seu Pedido: {props.successData.paymentResponse.id}</h3>
                        </div>
                        <div className="checkout-payment">
                            {props.successData.paymentType === PaymentType.PaymentOnTakeOff &&
                                <div className="payment-info">
                                    <h4>Agora é só realizar o pagamento no valor de {Price(props.successData.totalValue)} no ato da entrega</h4>
                                </div>
                            }
                            {props.successData.paymentType === PaymentType.BankTicket &&
                                <div className="payment-info">
                                    <h4>Faça o download do boleto abaixo no valor de {Price(props.successData.totalValue)}, e realize o pagamento em até no máximo 3 dias.</h4>
                                    <div className="payment-info-data">
                                        {props.successData.paymentResponse.paymentData && <>
                                            <div className="payment-image">
                                                <i className="material-icons-outlined">description</i>
                                            </div>
                                            <div className="payment-link">
                                                <a href="#" onClick={() => copyBarCode()}>
                                                    <p>Copiar código de barras</p>
                                                    <i className="material-icons-outlined">content_copy</i>
                                                </a>
                                            </div>
                                            <div className="button-boleto-download">
                                                <a className="btn btn-form text-icon" href={props.successData.paymentResponse.paymentData.externalSourceUrl} target="_blank">
                                                    Baixar Boleto <i className="material-icons-outlined">download</i>
                                                </a>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            }
                            {props.successData.paymentType === PaymentType.PixTransfer &&
                                <div className="payment-info">
                                    <h4>Realize o pagamento no valor de {Price(props.successData.totalValue)} utilizando o QR-Code, ou a chave PIX abaixo</h4>
                                    <div className="payment-info-data">
                                        {props.successData.paymentResponse.paymentData && <>
                                            <div className="payment-image">
                                                <img src={`data:image/jpeg;base64, ${props.successData.paymentResponse.paymentData.qrCodeBase64}`} alt="" />
                                            </div>
                                            <div className="button-boleto-download">
                                                <a className="btn btn-form text-icon" href="#" onClick={() => copyPixCode()}>
                                                    Copiar chave PIX <i className="material-icons-outlined">content_copy</i>
                                                </a>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            }
                            {props.successData.paymentType === PaymentType.CreditCard &&
                                <div className="payment-info">
                                    <h4>Pagamento no valor de {Price(props.successData.totalValue)} realizado no cartão</h4>
                                </div>
                            }
                        </div>
                        <div className="back-to-site">
                            <a href="/">
                                <i className="material-icons-outlined">arrow_backward</i>
                                <p>Voltar para o site</p>
                            </a>
                        </div>
                    </div>
                }
            </div>
            <div className="checkout-column cart-items">
                <CheckoutProducts
                    cartProducts={props.successData.cartProducts}
                    subTotal={props.successData.subTotalValue}
                    shippingType={props.successData.shippingType}
                    discountCoupon={props.successData.discountCoupon}
                    total={props.successData.totalValue}
                />
            </div>
        </div>
    );
}