import './checkSize.css';

interface Props {
    showModal: boolean;
    setShowModal(value: boolean): void;
}

export function CheckSize(props: Props) {
    return (
        <div className={`modal no-scroll ${props.showModal ? "show" : ""}`}>
            <div className="modal-block-touch"></div>
            <div className="modal-content">
                <div className="modal-header">
                    <div className="text-icon">
                        <i className="material-symbols-outlined">straighten</i>
                        <span>Verifique seu tamanho</span>
                    </div>
                    <button className="btn btn-close-modal" type="button" onClick={() => props.setShowModal(false)}>
                        <i className="material-icons-outlined">close</i>
                    </button>
                </div>
                <div className="modal-body">
                    <table className="check-size-table">
                        <thead>
                            <tr className="table-header">
                                <td>Tipo</td>
                                <td>Tamanho</td>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td>Tam único</td>
                                <td>Veste do 38 ao 42</td>
                            </tr>
                            <tr>
                                <td>P</td>
                                <td>Veste 38</td>
                            </tr>
                            <tr>
                                <td>M</td>
                                <td>Veste 40</td>
                            </tr>
                            <tr>
                                <td>G</td>
                                <td>Veste 42</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className="obs-check-site">OBS: Esta é uma informação geral. Para detalhes específicos de cada item, consulte a tabela acima de acordo com suas especificações. Em caso de dúvidas, sinta-se à vontade para entrar em contato com nossos consultores através dos nossos canais. Será um prazer ajudar você!</p>
                </div>
            </div>
        </div>
    );
}
