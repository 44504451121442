import { LoginResponse } from '../contracts/UserContracts';
import { BaseResponse } from '../entities/BaseResponse';
import { BaseService } from './BaseService';

export class AuthService extends BaseService {
    static myInstance: AuthService = null;

    static getInstance() {
        if (AuthService.myInstance === null) {
            AuthService.myInstance = new AuthService();
        }

        return this.myInstance;
    }

    async refreshToken(refreshToken: string): Promise<BaseResponse<LoginResponse>> {
        return await super.post("/auth/refresh", { refreshToken: refreshToken });
    }

    async login(email: string, password: string): Promise<BaseResponse<LoginResponse>> {
        return await super.post("/auth/login", { email: email, password: password });
    }

    async logout(): Promise<BaseResponse<any>> {
        return await super.post("/auth/logout");
    }
}