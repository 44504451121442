import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './checkout-styles.css';
import { SharedContext } from '../../contexts/SharedContext';
import { UserContext } from '../../contexts/UserContext';
import { AuthService } from '../../services/AuthService';

interface Props {
    email: string;
    password: string;
    setEmail(value: string): void;
    setPassword(value: string): void;
    showLoading(value: boolean): void;
}

export function CheckoutLogin(props: Props) {
    const sharedContext = useContext(SharedContext);
    const userContext = useContext(UserContext);
    const history = useHistory();

    const [showValidFields, setShowValidFields] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    async function tryLogin() {
        setShowValidFields(false);
        if (props.email === '' || props.password === '') {
            sharedContext.alert(false, 'Informe e-mail e senha para fazer o login. ');
            setShowValidFields(true);
            return;
        }

        props.showLoading(true);

        var loginResponse = await AuthService.getInstance().login(props.email, props.password);
        if (!loginResponse.success) {
            sharedContext.alert(false, loginResponse.errors[0].message);
            props.showLoading(false);
            return;
        }

        userContext.handleToken(loginResponse.data.token, loginResponse.data.refreshToken);

        props.setEmail('');
        props.setPassword('');
        props.showLoading(false);

        history.replace("/checkout/endereco");
    }

    return (
        <form className="form-box" action="">
            <div className="form-content">
                <div className="title">
                    Bem vindo de volta, informe sua senha para entrar.
                </div>
                <div className="form-input">
                    <span>E-mail</span>
                    <div className="input-box">
                        <input
                            className={`input-field light ${showValidFields && props.email === '' ? 'invalid' : ''}`}
                            type="email"
                            placeholder="Digite seu e-mail"
                            value={props.email}
                            onChange={(e) => props.setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-input">
                    <span>Senha</span>
                    <div className="input-box">
                        <input
                            className={`input-field light ${showValidFields && props.password === '' ? 'invalid' : ''}`}
                            type={showPassword ? "text" : "password"}
                            placeholder="Digite sua senha"
                            value={props.password}
                            onChange={(e) => props.setPassword(e.target.value)}
                        />
                        <button className="input-button btn light" type="button" onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <i className="material-icons-outlined">visibility_on</i> : <i className="material-icons-outlined">visibility_off</i>}
                        </button>
                    </div>
                </div>
                <div className="form-input">
                    <button className="btn btn-form" type="button" onClick={() => tryLogin()}>Continuar</button>
                </div>
                <div className="form-links">
                    <Link to={"/esqueci-minha-senha"}>Esqueceu sua senha?</Link>
                </div>
            </div>
        </form>
    );
}