import { GetAddressInfoResponse } from '../contracts/SharedContracts';
import { AppSettings } from '../settings/AppSettings';
import { BaseService } from './BaseService';

export class AddressService extends BaseService {
    static myInstance: AddressService = null;
    private url = AppSettings.ZipCodeInfoApiUrl;

    static getInstance(){
        if(AddressService.myInstance === null)
            AddressService.myInstance = new AddressService();
        
        return this.myInstance;
    }

    async getAddress(zipCode: string): Promise<GetAddressInfoResponse> {
        return await super.getFullUrl(this.url + "/" +  zipCode + "/json");
    }
}