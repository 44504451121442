import { useContext, useEffect, useState } from 'react';
import './checkout-styles.css';
import { UserService } from '../../services/UserService';
import { useHistory } from 'react-router-dom';
import { SharedContext } from '../../contexts/SharedContext';

interface Props {
    email: string;
    setEmail(value: string): void;
    showLoading(value: boolean): void;
}

export function CheckoutEmail(props: Props) {
    const sharedContext = useContext(SharedContext);
    const history = useHistory();
    const [showValidFields, setShowValidFields] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    async function checkEmail() {
        setShowValidFields(false);
        if (!props.email || props.email.length === 0 || !props.email.includes("@") || !props.email.includes(".") || props.email.length < 10) {
            sharedContext.alert(false, "Preencha um e-mail válido para continuar");
            setShowValidFields(true);
            return;
        }

        props.showLoading(true);
        var response = await UserService.getInstance().checkEmail(props.email);
        if (!response.success) {
            props.showLoading(false);
            history.push("/checkout/cadastro-cliente");
            return;
        }

        history.push("/checkout/login");
        props.showLoading(false);
    }

    return (
        <form className="form-box" action="">
            <div className="form-content">
                <div className="title">
                    Para finalizar sua compra, informe seu E-mail
                </div>
                <div className="form-input">
                    <span>E-mail</span>
                    <div className="input-box">
                        <input
                            className={`input-field light ${showValidFields && props.email === '' ? 'invalid' : ''}`}
                            type="email"
                            placeholder="Digite seu e-mail"
                            value={props.email}
                            onChange={(e) => props.setEmail(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-input">
                    <button className="btn btn-form" type="button" onClick={() => checkEmail()}>Continuar</button>
                </div>
            </div>
        </form>
    );
}