import { Link, useHistory } from 'react-router-dom';
import { Cart } from '../cart/Cart';
import { useContext, useEffect, useState } from 'react';
import { BrandEntity, CategoryEntity, CollectionEntity } from '../../entities/DomainEntity';
import { UserContext } from '../../contexts/UserContext';
import { CartContext } from '../../contexts/CartContext';

interface Props {
    showLoading(value: boolean): void;
    setNoScroll(value: boolean): void;
    categories: CategoryEntity[];
    brands: BrandEntity[];
    collections: CollectionEntity[];
}

export function Header(props: Props) {
    const userContext = useContext(UserContext);
    const cartContext = useContext(CartContext);
    const history = useHistory();
    const [menuState, setMenuState] = useState(false);
    const [cartState, setCartState] = useState(false);
    const [searchText, setSearchText] = useState("");
    var interval: any;

    useEffect(() => {
        timer();

        return () => {
            clearInterval(interval);
        }
    }, []);

    function timer() {
        const infoTexts = document.querySelectorAll(".header-top-text");
        const totalInfoLength = infoTexts.length;
        let infoIndex = 0;
        interval = setInterval(() => {
            infoTexts.forEach((element) => {
                element.classList.remove("fade-in");
                element.classList.add("fade-out");
            });

            infoTexts[infoIndex].classList.add("fade-in");
            infoTexts[infoIndex].classList.remove("fade-out");

            if ((infoIndex + 1) >= totalInfoLength)
                infoIndex = 0;
            else
                infoIndex++;
        }, 3000);
    }

    function search(isMobileField: boolean) {
        if (searchText === "") {
            document.getElementById(`search-field${isMobileField ? "-mobile" : ""}`).focus();
            return;
        }

        history.push("/?nome=" + searchText);
    }

    function logout() {
        handleMenuState(false);
        userContext.logout();
    }

    function handleMenuState(state: boolean) {
        const btnDropdowns = document.querySelectorAll(".dropdown");
        btnDropdowns.forEach(x => x.classList.remove("show"));
        setMenuState(state);
        if (state || cartState) {
            props.setNoScroll(true);
            return;
        }

        props.setNoScroll(false);
    }

    function handleCartState(value: boolean) {
        setCartState(value);
        if (value || menuState) {
            props.setNoScroll(true);
            return;
        }

        props.setNoScroll(false);
    }

    const btnDropdowns = document.querySelectorAll(".dropdown");
    const handleDropdown = (event: any) => {
        btnDropdowns.forEach(x => x.classList.remove("show"));
        event.currentTarget.classList.add("show");
    }

    return (
        <>
            <section className="header">
                <div className="info">
                    {/* <label className={`header-top-text`}>Frete grátis para compras acima de R$ 100,00</label> */}
                    <label className={`header-top-text`}>Parcelamos todo o site em até 12x</label>
                    <label className={`header-top-text fade-out`}>Descontos em cupons apartir de 10%</label>
                    <label className={`header-top-text fade-out`}>Enviamos para todo o Brasil</label>
                </div>
                <header>
                        <nav className={`nav ${menuState ? "active" : ""}`}>
                            <button className="btn-mobile" onClick={() => handleMenuState(!menuState)}>
                                <i className="hamburger"></i>
                            </button>
                            <ul className="menu">
                                <li>
                                    <a href="/">
                                        <span>Início</span>
                                    </a>
                                </li>
                                <li className="dropdown" onClick={handleDropdown}>
                                    <div className="dropdown-btn text-icon">
                                        <span>Categorias</span>
                                        <i className="dropdown-icon material-icons-outlined">expand_more</i>
                                    </div>
                                    <div className="dropdown-options">
                                        {props.categories.map(x => (
                                            <Link to={`/?categorias=${x.url}`} key={x.url} onClick={() => handleMenuState(false)}>
                                                <span>{x.title}</span>
                                            </Link>
                                        ))}
                                    </div>
                                </li>
                                <li className="dropdown" onClick={handleDropdown}>
                                    <div className="dropdown-btn text-icon">
                                        <span>Coleções</span>
                                        <i className="dropdown-icon material-icons-outlined">expand_more</i>
                                    </div>
                                    <div className="dropdown-options">
                                        {props.collections.map(x => (
                                            <Link to={`/?colecoes=${x.url}`} key={x.url} onClick={() => handleMenuState(false)}>
                                                <span>{x.title}</span>
                                            </Link>
                                        ))}
                                    </div>
                                </li>
                                {/* <li className="dropdown" onClick={handleDropdown}>
                                <div className="dropdown-btn text-icon">
                                    <span>Marcas</span>
                                    <i className="dropdown-icon material-icons-outlined">expand_more</i>
                                </div>
                                <div className="dropdown-options">
                                    {props.brands.map(x => (
                                        <Link to={`/?marcas=${x.url}`} key={x.url} onClick={() => handleMenuState(false)}>
                                            <span>{x.title}</span>
                                        </Link>
                                    ))}
                                </div>
                            </li> */}
                                <li>
                                    <Link to={"/contato"} onClick={() => handleMenuState(false)}>
                                        <span>Contato</span>
                                    </Link>
                                </li>
                                {userContext.user ?
                                    <li className="dropdown btn-user-mobile" onClick={handleDropdown}>
                                        <div className="dropdown-btn text-icon">
                                            <div className="text-icon">
                                                <i className="material-icons-outlined">person</i>
                                                <span>{`Olá ${userContext.user.name.split(" ")[0]}`}</span>
                                            </div>
                                            <i className="dropdown-icon dropdown-icon material-icons-outlined">expand_more</i>
                                        </div>
                                        <div className="dropdown-options">
                                            <Link to={"/minha-conta/pedidos"} onClick={() => handleMenuState(false)}>
                                                <div className="text-icon">
                                                    <i className="material-icons-outlined">inventory_2</i>
                                                    <span>Meus pedidos</span>
                                                </div>
                                            </Link>
                                            <a href="#" onClick={() => logout()}>
                                                <div className="text-icon">
                                                    <i className="material-icons-outlined">logout</i>
                                                    <span>Sair</span>
                                                </div>
                                            </a>
                                        </div>
                                    </li>
                                    :
                                    <li>
                                        <Link to={"/login"} className="text-icon btn-user-mobile" style={{ display: "flex !important" }} onClick={() => handleMenuState(false)}>
                                            <i className="material-icons-outlined">person</i>
                                            <span>Entrar | Cadastrar</span>
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </nav>
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/favicon.png" width="35" height="35" alt="" />
                                <h1>Cangaço Store</h1>
                            </a>
                        </div>
                        <div className="buttons" style={{ gap: "8px" }}>
                            <div className="input-box">
                                <input className="input-field" id="search-field" type="text" placeholder="O que está procurando?" onChange={e => setSearchText(e.target.value)} value={searchText} />
                                <a className="input-button" onClick={() => search(false)}>
                                    <i className="material-icons-outlined">search</i>
                                </a>
                            </div>
                            {userContext.user ?
                                <button className="dropdown btn btn-user">
                                    <div className="dropdown-btn text-icon">
                                        <i className="material-icons-outlined">person</i>
                                        <span>{`Olá ${userContext.user.name.split(" ")[0]}`}</span>
                                        <i className="dropdown-icon material-icons-outlined">expand_more</i>
                                    </div>
                                    <div className="dropdown-options" style={{ right: "50px" }}>
                                        <Link to={"/minha-conta/pedidos"} style={{ display: "block", padding: "0.5rem" }}>
                                            <div className="text-icon">
                                                <i className="material-icons-outlined">inventory_2</i>
                                                <span>Meus pedidos</span>
                                            </div>
                                        </Link>
                                        <a href="#" style={{ display: "block", padding: "0.5rem" }} onClick={() => userContext.logout()}>
                                            <div className="text-icon">
                                                <i className="material-icons-outlined">logout</i>
                                                <span>Sair</span>
                                            </div>
                                        </a>
                                    </div>
                                </button>
                                :
                                <button className="dropdown btn btn-user">
                                    <div className="dropdown-btn text-icon">
                                        <i className="material-icons-outlined">person</i>
                                        <i className="dropdown-icon material-icons-outlined">expand_more</i>
                                    </div>
                                    <div className="dropdown-options" style={{ right: "50px" }}>
                                        <Link to={"/login"} style={{ display: "block", padding: "0.5rem" }}>
                                            <div className="text-icon">
                                                <i className="material-icons-outlined">logout</i>
                                                <span>Entrar | Cadastrar</span>
                                            </div>
                                        </Link>
                                    </div>
                                </button>
                            }
                            <button className="btn btn-cart" onClick={() => handleCartState(true)}>
                                {cartContext.cartProducts && cartContext.cartProducts.length > 0 &&
                                    <span className="badge-cart-items">{cartContext.cartProducts.map(item => item.count).reduce((prev, next) => prev + next)}</span>
                                }
                                <i className="material-symbols-outlined">garden_cart</i>
                            </button>
                    </div>
                </header>
                <div className="input-box-mobile">
                    <div className="input-box">
                        <input className="input-field" id="search-field-mobile" type="text" placeholder="O que está procurando?" onChange={e => setSearchText(e.target.value)} value={searchText} />
                        <a className="input-button" onClick={() => search(true)}>
                            <i className="material-icons-outlined">search</i>
                        </a>
                    </div>
                </div>
                <div className={`block-toutch ${cartState ? "active" : ""}`} onClick={() => handleCartState(false)}></div>
            </section>

            <Cart
                cartState={cartState}
                setCartState={(state) => handleCartState(state)}
                showLoading={(bool) => props.showLoading(bool)}
                setNoScroll={(bool) => props.setNoScroll(bool)}
            ></Cart>
        </>
    );
}