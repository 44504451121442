import { AppSettings } from "../settings/AppSettings";

export class BaseService {
    private API_URL = AppSettings.ApiUrl;
    private TOKEN_NAME = AppSettings.LocalStorageTokenVarName;

    async getFullUrl(url: string, isLogged = false) {
        const response = await fetch(url);

        return await this.parseResponse(response, isLogged);
    }

    async get(url: string, isLogged: boolean = false) {
        const response = await fetch(this.API_URL + url, {
            method: 'GET',
            headers: this.setHeaders()
        });

        return await this.parseResponse(response, isLogged);
    }

    async post(url: string, data: object = {}, isLogged: boolean = false) {
        const response = await fetch(this.API_URL + url,
            {
                method: "POST",
                headers: this.setHeaders(),
                body: JSON.stringify(data)
            });
        return await this.parseResponse(response, isLogged);
    }

    async parseResponse(response: any, isLogged: boolean) {
        if (!response || !response.status)
            return { success: false, errors: [{ code: "500", message: "Erro ao processar requisição." }] };

        switch (response.status) {
            case 401:
                if (isLogged) 
                    window.location.reload();

                return await response.json();
            case 200:
            case 400:
                return await response.json();
            case 404:
                return { success: false, errors: [{ code: "500", message: "404 - Não foi possível encontrar o endereço solicitado." }] };
            case 500:
            default:
                return { success: false, errors: [{ code: "500", message: "Erro ao processar requisição." }] };
        }
    }

    private getToken(): string {
        var token = localStorage.getItem(this.TOKEN_NAME);
        return !token ? null : `Bearer ` + token;
    }

    private setHeaders() {
        var headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", this.getToken());

        return headers;
    }
}