import { SendMessageRequest } from '../contracts/DomainContracts';
import { BaseResponse } from '../entities/BaseResponse';
import { BrandEntity, CategoryEntity, CollectionEntity } from '../entities/DomainEntity';
import { ShippingTypeEntity } from '../entities/ShippingTypeEntity';
import { BaseService } from './BaseService';

export class DomainService extends BaseService {
    static myInstance: DomainService = null;

    static getInstance(){
        if(DomainService.myInstance === null){
            DomainService.myInstance = new DomainService();
        }

        return this.myInstance;
    }

    async getCategories(): Promise<BaseResponse<CategoryEntity[]>> {
        return await super.get("/domain/getcategories");
    }

    async getBrands(): Promise<BaseResponse<BrandEntity[]>> {
        return await super.get("/domain/getbrands");
    }

    async getCollections(): Promise<BaseResponse<CollectionEntity[]>> {
        return await super.get("/domain/getcollections");
    }

    async checkShipping(zipCode: string): Promise<BaseResponse<ShippingTypeEntity[]>> {
        return await super.get("/domain/checkshipping/" + zipCode);
    }

    async sendMessage(request: SendMessageRequest): Promise<BaseResponse<any>> {
        return await super.post("/domain/sendmessage", request);
    }
}
