import { useContext, useEffect, useState } from 'react';
import './contact-styles.css';
import { DomainService } from '../../services/DomainService';
import { maskedStoreCellPhone } from '../../helpers/PhoneHelper';
import { AppSettings } from '../../settings/AppSettings';
import { SharedContext } from '../../contexts/SharedContext';

interface Props {
    showLoading(value: boolean): void;
}

export function Contact(props: Props) {
    const sharedContext = useContext(SharedContext);

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showValidFields, setShowValidFields] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);
    });

    async function sendMessage() {
        setShowValidFields(false);
        if (!email || email === '') {
            sharedContext.alert(false, "Necessário preencher um e-mail válido.");
            setShowValidFields(true);
            return;
        }

        if (!message || message === '') {
            sharedContext.alert(false, "Necessário inserir uma mensagem para enviar.");
            setShowValidFields(true);
            return;
        }

        const request = {
            email: email,
            message: message
        };

        props.showLoading(true);
        const response = await DomainService.getInstance().sendMessage(request);
        if (!response.success) {
            props.showLoading(false);
            sharedContext.alert(false, response.errors[0].message);
            return;
        }

        props.showLoading(false);
        sharedContext.alert(true, "Obrigado! Sua mensagem foi enviada com sucesso.");
        setEmail('');
        setMessage('');
        setShowValidFields(false);
    }
    return (
        <section className="static-info">
            <div className="static-info-container">
                <div className="static-info-column">
                    <div className="static-info-title">
                        <h2>Contato</h2>
                    </div>
                    <div className="static-info-message">
                        Nos deixe uma mensagem!
                    </div>
                    <form>
                        <div className="form-input">
                            <span>E-mail</span>
                            <div className="input-box">
                                <input
                                    className={`input-field light ${showValidFields && email === '' ? 'invalid' : ''}`}
                                    type="email"
                                    placeholder="Digite seu e-mail"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                <button className="input-button btn light" type="button">
                                    <i className="material-icons-outlined">email</i>
                                </button>
                            </div>
                        </div>
                        <div className="form-input">
                            <span>Mensagem</span>
                            <textarea
                                className={`textarea-field ${showValidFields && message === '' ? 'invalid' : ''}`}
                                placeholder="Como podemos ajudar?"
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                rows={4}
                            ></textarea>
                        </div>
                        <div className="form-input">
                            <button type="button" className="btn btn-form" onClick={() => sendMessage()}>Enviar</button>
                        </div>
                    </form>
                </div>
                <div className="static-info-column">
                    <h2 className="channels-title">Nossos canais</h2>
                    <div className="contact-items">
                        <div className="contact-info-item">
                            <img src="/assets/images/whatsapp.png" alt="whatsapp" width={20} height={20} />
                            <span>{maskedStoreCellPhone()}</span>
                        </div>
                        <div className="contact-info-item">
                            <i className="material-icons-outlined">phone</i>
                            <span>{maskedStoreCellPhone()}</span>
                        </div>
                        <div className="contact-info-item">
                            <i className="material-icons-outlined">email</i>
                            <span>{AppSettings.StoreEmailAddress}</span>
                        </div>
                        <div className="contact-info-item">
                            <span>Seg a sab das 9h-18h</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
