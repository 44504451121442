import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Newsletter } from '../newslether/Newsletter';
import { AppSettings } from '../../settings/AppSettings';
import { maskedStoreCellPhone } from '../../helpers/PhoneHelper';
import { CategoryEntity } from '../../entities/DomainEntity';

interface Props {
	categories: CategoryEntity[];
	showLoading(value: boolean): void;
}

export function Footer(props: Props) {
	const myRef: any = useRef();

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, []);

	const handleScroll = () => {
		if (!myRef?.current?.style)
			return;

		if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
			myRef.current.style.display = "block";
			return;
		}

		myRef.current.style.display = "none";
	}

	return (
		<section className="footer">
			<div className="footer-content">
					<div className="footer-column">
						<h4>Precisa de ajuda?</h4>
						<ul>
							<li>
								<Link to="/contato">
									Contato
								</Link>
							</li>
							<li>
								<Link to="/politica-de-reembolso">
									Reembolso
								</Link>
							</li>
							<li>
								<Link to="/politica-de-privacidade">
									Política de privacidade
								</Link>
							</li>
							<li>
								<Link to="/perguntas-frequentes">
									Perguntas frequentes
								</Link>
							</li>
							<li>
								<Link to="/frete">
									Frete
								</Link>
							</li>
						</ul>
					</div>
					<div className="footer-column">
						<h4>Categorias</h4>
						<ul>
							{props.categories.map(x => (
								<li key={x.url}>
									<Link to={"/?categorias=" + x.url}>{x.title}</Link>
								</li>
							))}
						</ul>
					</div>
					<div className="footer-column">
						<h4>Formas de pagamento</h4>
						<div className="payment-icons">
							<span>
								<img src="/assets/icons/icon-visa.svg" alt="ICON-PAY" />
							</span>
							<span>
								<img src="/assets/icons/icon-master.svg" alt="ICON-PAY" />
							</span>
							<span>
								<img src="/assets/icons/icon-elo.svg" alt="ICON-PAY" />
							</span>
							<span>
								<img src="/assets/icons/icon-amex.svg" alt="ICON-PAY" />
							</span>
							<span>
								<img src="/assets/icons/icon.svg" alt="ICON-PAY" />
							</span>
							<span>
								<img src="/assets/icons/icon-boleto.svg" alt="ICON-PAY" />
							</span>
							<span>
								<img src="/assets/icons/icon-pix.svg" alt="ICON-PAY" />
							</span>
						</div>
					</div>
					<div className="footer-column">
						<h4>
							Onde nos encontrar
						</h4>
						{/* <p>
									Alguma dúvida? Nos deixa ajudá-lo através do nosso e-mail loja@cangacostore.com or entre em contato pelo número (85) 99999-9999
								</p> */}

						<p>Alguma dúvida? Entre em contato através de nossos canais. Será um prazer ajudar!</p>
						<ul>
							<li>{maskedStoreCellPhone()}</li>
							<li>{AppSettings.StoreEmailAddress}</li>
						</ul>
						<div className="social-media">
							<a target="_blank" href="https://www.instagram.com/store.cangaco" rel="noopener noreferrer">
								<img src="/assets/icons/icon-instagram.svg" alt="ICON-SOCIAL-MEDIA" />
							</a>
							<a target="_blank" href="https://www.instagram.com/store.cangaco" rel="noopener noreferrer">
								<img src="/assets/icons/icon-facebook.svg" alt="ICON-SOCIAL-MEDIA" />
							</a>
							<a target="_blank" href="https://api.whatsapp.com/send?phone=5585992938350&text=Olá, estou entrando em contato atraves do site cangacostore.com.br" rel="noopener noreferrer">
								<img src="/assets/icons/icon-whatsapp.svg" alt="ICON-SOCIAL-MEDIA" />
							</a>
							<a target="_blank" href="https://cangacostore.com.br" rel="noopener noreferrer">
								<img src="/assets/icons/icon-site.svg" alt="ICON-SOCIAL-MEDIA" />
							</a>
						</div>
					</div>
					<div className="footer-column">
						<Newsletter showLoading={() => props.showLoading} />
				</div>
			</div>
			<div className="footer-copyright">
				<span>&copy; {new Date().getFullYear()} Cangaço Store | Todos os direitos reservados.</span>
			</div>
			<div className="btn btn-to-top" ref={myRef}>
				<button onClick={() => window.scrollTo(0, 0)}>
					<i className="material-icons-outlined">expand_less</i>
				</button>
			</div>
		</section>
	);
}