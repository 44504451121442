export class AppSettings {
    static AppInMaintenance: boolean = (process.env.REACT_APP_IN_MAINTENANCE.toLowerCase() === "true");
    static ZipCodeInfoApiUrl: string = process.env.REACT_APP_ZIPCODE_INFO_API_URL;
    static ApiUrl: string = process.env.REACT_APP_API_URL;

    static LocalStorageCartVarName: string = process.env.REACT_APP_LOCAL_STORAGE_CART_VAR_NAME;
    static LocalStorageTokenVarName: string = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_VAR_NAME;
    static LocalStorageRefreshTokenVarName: string = process.env.REACT_APP_LOCAL_STORAGE_REFRESH_TOKEN_VAR_NAME;
    static LastProductsLimit: number = Number(process.env.REACT_APP_LAST_PRODUCTS_LIMIT);
    static TopSellingProductsLimit: number = Number(process.env.REACT_APP_TOPSELLING_PRODUCTS_LIMIT);
    static MercadoPagoPublicKey: string = process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY;

    static StoreCellPhoneNumber: string = process.env.REACT_APP_STORE_CELLPHONE_NUMBER;
    static StoreEmailAddress: string = process.env.REACT_APP_STORE_EMAIL_ADDRESS;
}