import { useEffect } from "react";
import { AppSettings } from "../../settings/AppSettings";
import { maskedStoreCellPhone } from "../../helpers/PhoneHelper";

export function Shipping() {
    useEffect(() => {
        window.scroll(0, 0);
    });
    return (
        <div className="information-screen">
            <div className="information-content">
                <h1>Informações sobre Frete</h1>

                <h2>Opções de Frete</h2>
                <p>Oferecemos várias opções de frete para atender às suas necessidades. As opções disponíveis podem variar de acordo com a sua localização e incluem:</p>
                <ul>
                    <li>Entrega por motoboy: Entrega em até 1 dia útil</li>
                    <li>Frete Padrão: Entrega em 5 a 10 dias úteis</li>
                    <li>Frete Expresso: Entrega em 2 a 5 dias úteis</li>
                </ul>
                <span>OBS: Prazos aproximados</span>

                <h2>Custo do Frete</h2>
                <p>O custo do frete é calculado com base no peso e nas dimensões dos itens comprados, bem como na localização de entrega. Você pode visualizar o custo total do frete ao finalizar a compra. </p>

                <h2>Processamento de Pedidos</h2>
                <p>Os pedidos são processados dentro de 1 a 2 dias úteis após a confirmação do pagamento. Você receberá uma notificação por e-mail quando seu pedido for enviado.</p>

                <h2>Rastreamento de Pedidos</h2>
                <p>Após o envio do seu pedido, enviaremos um e-mail com o número de rastreamento e um link para acompanhar a entrega. Você pode usar este número para verificar o status do seu pedido a qualquer momento.</p>

                <h2>Entregas Internacionais</h2>
                <p>Sim, enviamos para vários países ao redor do mundo. O tempo de entrega e o custo do frete para pedidos internacionais variam conforme a localização. Por favor, esteja ciente de que podem ser aplicados impostos e taxas de importação, que são de responsabilidade do cliente.</p>

                <h2>Problemas com a Entrega</h2>
                <p>Se você encontrar algum problema com a entrega do seu pedido, como atrasos ou itens danificados, entre em contato conosco imediatamente através do telefone/whatsapp {maskedStoreCellPhone()} ou do e-mail {AppSettings.StoreEmailAddress}. Estamos aqui para ajudar e resolver qualquer problema o mais rápido possível.</p>

                <h2>Endereço de Entrega</h2>
                <p>Certifique-se de fornecer um endereço de entrega correto e completo ao fazer seu pedido. Não nos responsabilizamos por entregas não realizadas devido a endereços incorretos ou incompletos fornecidos pelo cliente.</p>

                <h2>Contato</h2>
                <p>Se você tiver qualquer dúvida sobre nossas opções de frete, entre em contato conosco em:</p>
                <p>
                    Cangaço Store<br />
                    {AppSettings.StoreEmailAddress}<br />{
                        maskedStoreCellPhone()}</p>
                <p>Estamos aqui para ajudar!</p>
            </div>
        </div>
    );
}