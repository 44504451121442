import { useState } from 'react';
import toast from 'react-hot-toast';
import { UserService } from '../../services/UserService';

interface Props {
    showLoading(value: boolean): void;
}

export function Newsletter(props: Props) {
    const [userService, setUserService] = useState(UserService.getInstance());
    const [email, setEmail] = useState('');

    async function sendRequest() {
        if (email === '') {
            document.getElementById("send-request-field").focus();
            return;
        }

        if (!validateEmail(email)) {
            toast.error("E-mail inválido!");
            return;
        }

        props.showLoading(true);
        var newsletterSignUpResponse = await userService.signup(email);
        if (!newsletterSignUpResponse.success) {
            toast.error(newsletterSignUpResponse.errors && newsletterSignUpResponse.errors.length > 0 ? newsletterSignUpResponse.errors[0].message : "Erro no envio da requisição.");
            props.showLoading(false);
            return;
        }

        props.showLoading(false);
        setEmail('');
        toast.success("Inscrição realizada com sucesso! Enviamos um e-mail pra você com mais informações.");
    }

    function validateEmail(email: string){
        var isValid = true;

        if (!email.includes("@"))
            isValid = false;

        if (!email.includes("."))
            isValid = false;

        if (email.length < 10)
            isValid = false;

        return isValid;
    }

    return (
        <div>
            <h4>Newsletter</h4>
            <p>Inscreva-se para receber novidades e promoções</p>
            <div className="input-box">
                <input className="input-field" id="send-request-field" type="email" placeholder="Digite seu e-mail" onChange={e => setEmail(e.target.value)} value={email} />
                <a className="input-button" onClick={() => sendRequest()}>
                    <i className="material-icons-outlined">send</i>
                </a>
            </div>
        </div>
    );
}