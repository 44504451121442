import './maintenance.css';

export function Maintenance() {
    return (
        <>
            <div className="maintenance">
                <img className="background-image" src="/assets/images/maintenance-background.png" alt="background-image" />
                <div className="maintenance-content">
                    <div className="maintenance-title">
                        <h2>ESTAMOS EM MANUTENÇÃO</h2>
                    </div>
                    <div className="maintenance-image">
                        <img className="maintenance-logo" src="/assets/images/favicon.png" alt="maintenance-logo" />
                    </div>
                    <div className="maintenance-text">
                        <p>Num se avéxe não que jajá nós tamo de volta!</p>
                        <label>Previsão de retorno 19/01 às 14:00</label>
                    </div>
                </div>
            </div>
        </>
    );
}