import { useEffect, useState } from 'react';
import { ProductService } from '../../services/ProductService';
import { Product } from '../../components/products/Product';
import { BrandEntity, CategoryEntity, CollectionEntity } from '../../entities/DomainEntity';
import { useHistory } from 'react-router-dom';

interface Props {
	showLoading(value: boolean): void;
	categories: CategoryEntity[];
	brands: BrandEntity[];
	collections: CollectionEntity[];
}

interface Filters {
	name: string;
	categories: string[];
	brands: string[];
	collections: string[];
	colors: string[];
	order: string;
	price: string;
}

export function Products(props: Props) {
	const history = useHistory();

	const [url, setUrl] = useState("");
	const [products, setProducts] = useState([]);
	const [filters, setFilters] = useState<Filters>({ name: '', categories: [], brands: [], collections: [], colors: [], order: '', price: '150' });
	const [orderState, setOrderState] = useState(false);
	const [filterState, setFilterState] = useState(false);
	const [gridState, setGridState] = useState('');
	const [minPriceLimit, setMinPriceLimit] = useState(40);
	const [maxPriceLimit, setMaxPriceLimit] = useState(150);

	useEffect(() => {
		setGridState(document.documentElement.clientWidth <= 1000 ? 'two-column' : 'four-column');
		setUrl(window.location.pathname + window.location.search);

		props.showLoading(true);
		updateFilters();
		updateProductList();

		if (url !== window.location.pathname + window.location.search) {
			if (url.includes("?")) { // possui filtros ativos
				const productListRef: any = document.querySelector(".products");
				const headerLimit = productListRef.offsetWidth < 1000 ? 155 : 100;
				window.scrollTo(0, productListRef.offsetTop - headerLimit);
			}

			setUrl(window.location.pathname + window.location.search);
			updateFilters();
			updateProductList();
		}
	}, [window.location.search]);

	function getFilters() {
		const search = window.location.search;
		const filters: any = {
			name: new URLSearchParams(search).get("nome"),
			categories: new URLSearchParams(search).get("categorias"),
			brands: new URLSearchParams(search).get("marcas"),
			collections: new URLSearchParams(search).get("colecoes"),
			colors: new URLSearchParams(search).get("cores"),
			order: new URLSearchParams(search).get("ordem"),
			price: new URLSearchParams(search).get("preco"),
		}

		var queryString = "?" + Object.keys(filters).map((key: any) => filters[key] != null && filters[key].length > 0 ? key + '=' + filters[key] : "").filter((x) => x.length > 0).join('&');
		return queryString;
	}

	function updateFilters() {
		const filters = {
			name: new URLSearchParams(window.location.search).get("nome") || '',
			categories: new URLSearchParams(window.location.search).get("categorias") ? new URLSearchParams(window.location.search).get("categorias").split(",") : [],
			brands: new URLSearchParams(window.location.search).get("marcas") ? new URLSearchParams(window.location.search).get("marcas").split(",") : [],
			collections: new URLSearchParams(window.location.search).get("colecoes") ? new URLSearchParams(window.location.search).get("colecoes").split(",") : [],
			colors: new URLSearchParams(window.location.search).get("cores") ? new URLSearchParams(window.location.search).get("cores").split(",") : [],
			order: new URLSearchParams(window.location.search).get("ordem") || '',
			price: new URLSearchParams(window.location.search).get("preco") || maxPriceLimit,
		} as Filters;

		setFilters(filters);
	}

	function addFilter(key: string, value: string) {
		var filtersRef = filters;

		switch (key) {
			case "name":
				filtersRef.name = value;
				break;
			case "categories":
				filtersRef.categories = !filtersRef.categories ? [] : filtersRef.categories;
				filtersRef.categories.push(value);
				break;
			case "brands":
				filtersRef.brands = !filtersRef.brands ? [] : filtersRef.brands;
				filtersRef.brands.push(value);
				break;
			case "collections":
				filtersRef.collections = !filtersRef.collections ? [] : filtersRef.collections;
				filtersRef.collections.push(value);
				break;
			case "colors":
				filtersRef.colors = !filtersRef.colors ? [] : filtersRef.colors;
				filtersRef.colors.push(value);
				break;
			case "order":
				filtersRef.order = value;
				break;
			case "price":
				filtersRef.price = value;
				break;
			default:
				break;
		}

		setFilters({ ...filtersRef });
	}

	function removeFilter(key: string, value: string) {
		var filtersRef = filters;

		switch (key) {
			case "name":
				filtersRef.name = '';
				break;
			case "categories":
				filtersRef.categories = !filtersRef.categories ? [] : filtersRef.categories;
				filtersRef.categories = filtersRef.categories.filter(x => x !== value);
				break;
			case "brands":
				filtersRef.brands = !filtersRef.brands ? [] : filtersRef.brands;
				filtersRef.brands = filtersRef.brands.filter(x => x !== value);
				break;
			case "collections":
				filtersRef.collections = !filtersRef.collections ? [] : filtersRef.collections;
				filtersRef.collections = filtersRef.collections.filter(x => x !== value);
				break;
			case "colors":
				filtersRef.colors = !filtersRef.colors ? [] : filtersRef.colors;
				filtersRef.colors = filtersRef.colors.filter(x => x !== value);
				break;
			case "order":
				filtersRef.order = '';
				break;
			case "price":
				filtersRef.price = maxPriceLimit.toString();
				break;
			default:
				break;
		}

		setFilters({ ...filtersRef });
	}

	function setOrder(order: string) {
		if (order === '') {
			removeFilter("order", null);
			const filtersRef = filters;
			filtersRef.order = order;

			setFilters(filtersRef);
			handleFilter();

			return;
		}

		const filtersRef = filters;
		filtersRef.order = order;
		setFilters(filtersRef);
		handleFilter();
	}

	function handleFilter() {
		const filterSearch: any = {
			nome: filters.name,
			categorias: filters.categories,
			marcas: filters.brands,
			colecoes: filters.collections,
			cores: filters.colors,
			ordem: filters.order,
			preco: filters.price
		};
		var queryString = "?" + Object.keys(filterSearch).map(key => filterSearch[key] != null && filterSearch[key].length > 0 ? key + '=' + filterSearch[key] : "").filter((x) => x.length > 0).join('&');
		history.push(window.location.pathname + queryString);
	}

	function setButtonState(button: string) {
		if (button === 'orderState') {
			if (!orderState)
				setFilterState(false);

			setOrderState(!orderState);
			return;
		}

		if (!filterState)
			setOrderState(false);

		setFilterState(!filterState);
		return;
	}

	async function updateProductList() {
		props.showLoading(true);
		const productService = ProductService.getInstance();
		var productList = null;
		productList = await productService.getAllProducts(getFilters());
		if (productList.success)
			setProducts(productList.data.items);

		props.showLoading(false);
	}

	function setPrice(value: string) {
		var filtersRef = filters;
		filtersRef.price = value;

		setFilters({ ...filtersRef });
	}

	window.addEventListener("resize", () => {
		if (document.documentElement.clientWidth < 1000 && gridState === 'four-column')
			setGridState('two-column');

		if (document.documentElement.clientWidth > 1000 && gridState === 'one-column')
			setGridState('two-column');
	});

	return (
		<>
			<section className="filters">
				<div className="container-center">


					<div className="filter-container">
						<div className="filter-header">
							<div className="filter-buttons">
								<button className={`btn btn-form ${orderState ? "active" : ""}`} type="button" onClick={() => setButtonState('orderState')}>
									Ordem <i className="material-icons-outlined">sort</i>
								</button>
								<button className={`btn btn-form ${filterState ? "active" : ""}`} type="button" onClick={() => setButtonState('filterState')}>
									Filtros <i className="material-icons-outlined">filter_list</i>
								</button>
							</div>
							<div className="filter-links">
								<a className={`filter-links-grid ${gridState === "two-column" ? "active" : ""}`} type="button" href="#" onClick={() => setGridState('two-column')}>
									<i className="material-icons-sharp">grid_view</i>
								</a>
								<a className={`filter-links-grid ${gridState === "four-column" ? "active" : ""}`} type="button" href="#" onClick={() => setGridState('four-column')}>
									<i className="material-icons-sharp">apps</i>
								</a>
							</div>
							<div className="filter-links mobile">
								<a className={`filter-links-grid ${gridState === "one-column" ? "active" : ""}`} type="button" href="#" onClick={() => setGridState('one-column')}>
									<i className="material-icons-sharp">view_agenda</i>
								</a>
								<a className={`filter-links-grid ${gridState === "two-column" ? "active" : ""}`} type="button" href="#" onClick={() => setGridState('two-column')}>
									<i className="material-icons-sharp">grid_view</i>
								</a>
							</div>
						</div>
					</div>
					<div className="filter-container">
						{orderState &&
							<div className="filter-content">
								<div className="filter-column">
									<div className="filter-column-title">
										Ordenar por
									</div>
									<ul className="filter-column-items">
										<li className={`filter-column-item ${filters.order.length === 0 ? "selected" : ""}`}>
											<a onClick={() => setOrder('')}>Toda a loja</a>
										</li>
										<li className={`filter-column-item ${filters.order.includes("smaller-price") ? "selected" : ""}`}>
											<a onClick={() => setOrder("smaller-price")}>Menor preço</a>
										</li>
										<li className={`filter-column-item ${filters.order.includes("bigger-price") ? "selected" : ""}`}>
											<a onClick={() => setOrder("bigger-price")}>Maior Preço</a>
										</li>
										<li className={`filter-column-item ${filters.order.includes("best-seller") ? "selected" : ""}`}>
											<a onClick={() => setOrder("best-seller")}>Mais vendidos</a>
										</li>
										<li className={`filter-column-item ${filters.order.includes("newest") ? "selected" : ""}`}>
											<a onClick={() => setOrder("newest")}>Novidades</a>
										</li>
									</ul>
								</div>
							</div>
						}
						{filterState &&
							<div className="filter-content">
								<div className="filter-column">
									<div className="filter-column-title">
										Categorias
									</div>
									<ul className="filter-column-items">
										{props.categories && props.categories.map(x => (
											<li className={`filter-column-item ${filters.categories.includes(x.url) ? "selected" : ""}`} key={x.id}>
												<a onClick={() => filters.categories.includes(x.url) ? removeFilter("categories", x.url) : addFilter("categories", x.url)}>{x.title}</a>
											</li>
										))}
									</ul>
								</div>
								<div className="filter-column">
									<div className="filter-column-title">
										Coleções
									</div>
									<ul className="filter-column-items">
										{props.collections && props.collections.map(x => (
											<li className={`filter-column-item ${filters.collections.includes(x.url) ? "selected" : ""}`} key={x.id}>
												<a onClick={() => filters.collections.includes(x.url) ? removeFilter("collections", x.url) : addFilter("collections", x.url)}>{x.title}</a>
											</li>
										))}
									</ul>
								</div>
								<div className="filter-column">
									<div className="filter-column-title">
										Cores
									</div>
									<ul className="filter-column-items">
										<li className={`filter-column-item ${filters.colors.includes("Preto") ? "selected" : ""}`}>
											<a onClick={() => filters.colors.includes("Preto") ? removeFilter("colors", "Preto") : addFilter("colors", "Preto")}>Preto</a>
										</li>
										<li className={`filter-column-item ${filters.colors.includes("Branco") ? "selected" : ""}`}>
											<a onClick={() => filters.colors.includes("Branco") ? removeFilter("colors", "Branco") : addFilter("colors", "Branco")}>Branco</a>
										</li>
										<li className={`filter-column-item ${filters.colors.includes("Azul Marinho") ? "selected" : ""}`}>
											<a onClick={() => filters.colors.includes("Azul Marinho") ? removeFilter("colors", "Azul Marinho") : addFilter("colors", "Azul Marinho")}>Azul Marinho</a>
										</li>
										<li className={`filter-column-item ${filters.colors.includes("Cinza") ? "selected" : ""}`}>
											<a onClick={() => filters.colors.includes("Cinza") ? removeFilter("colors", "Cinza") : addFilter("colors", "Cinza")}>Cinza</a>
										</li>
									</ul>
								</div>
								<div className="filter-column">
									<div className="filter-column-title">
										Preço
									</div>
									<ul className="filter-column-items">
										<li className="filter-column-item">
											<div className="input-price">
												<input
													type="range"
													min={minPriceLimit}
													max={maxPriceLimit}
													value={filters.price}
													onChange={e => setPrice(e.target.value)}
												/>
												<label>R$ {filters.price},00</label>
											</div>
										</li>
									</ul>
								</div>
								<div className="filter-column filter-footer">
									<button className="btn btn-form" type="button" onClick={() => handleFilter()}>
										Filtrar <i className="material-icons-outlined">search</i>
									</button>
									<button className="btn btn-form light" type="button" onClick={() => {
										setOrderState(false);
										setFilterState(false);
										history.push("/");
									}}>
										Limpar <i className="material-icons-outlined">clear</i>
									</button>
								</div>
							</div>
						}
					</div>
				</div>
			</section>
			<section className={`products ${gridState}`}>
				{products && products.length > 0 ?
					products.map(x => <Product key={x.id} product={x} />)
					:
					<div className="list-empty">
						<div className="product-list-empty-info">
							<span>
								Não foi encontrado produtos para o filtro informado!
							</span>
							<i className="material-icons-outlined">search</i>
						</div>
					</div>
				}
			</section>
		</>
	);
}
