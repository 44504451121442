import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Price } from '../../helpers/PriceHelper';
import { TransactionService } from '../../services/TransactionService';
import { CartProductEntity } from '../../entities/CartProductEntity';
import { SharedContext } from '../../contexts/SharedContext';
import { CartContext } from '../../contexts/CartContext';
import { UserContext } from '../../contexts/UserContext';

interface Props {
    showLoading(value: boolean): void;
    setCartState(value: boolean): void;
    setNoScroll(value: boolean): void;
    cartState: boolean;
}

export function Cart(props: Props) {
    const sharedContext = useContext(SharedContext);
    const cartContext = useContext(CartContext);
    const userContext = useContext(UserContext);
    const myRef = useRef();

    useEffect(() => {
        return () => {
            props.setNoScroll(false);
        }
    }, []);

    const [discountCouponService, setDiscountCouponService] = useState(TransactionService.getInstance());
    const [discountCoupon, setDiscountCoupon] = useState(cartContext.discountCoupon !== null ? cartContext.discountCoupon.code : '');

    function setCountToUp(item: CartProductEntity) {
        if (item.count < item.stock) {
            const newProducts = cartContext.cartProducts.slice();
            newProducts.forEach(x => {
                if (x.url === item.url && x.color === item.color && x.size === item.size) {
                    x.count++;
                }
            });

            cartContext.updateCartProducts(newProducts);
        }
    }

    function setCountToDown(item: CartProductEntity) {
        if (item.count > 1) {
            const newProducts = cartContext.cartProducts.slice();
            newProducts.forEach(x => {
                if (x.url === item.url && x.color === item.color && x.size === item.size)
                    x.count--;
            });

            cartContext.updateCartProducts(newProducts);
        }
    }

    function removeProduct(product: CartProductEntity) {
        cartContext.removeCartProduct(product);
        if (cartContext.cartProducts.length === 1)
            removeCoupon(false);
    }

    async function addCoupon() {
        if (!discountCoupon || discountCoupon === '') {
            sharedContext.alert(false, "O cupom informado não é válido.");
            return;
        }

        props.showLoading(true);
        const document = userContext.user !== null ? userContext.user.document : null;
        var addCouponResponse = await discountCouponService.getByCode(discountCoupon, document);
        if (addCouponResponse.success) {
            cartContext.setDiscountCoupon(addCouponResponse.data);
            sharedContext.alert(true, "Cupom aplicado com sucesso");
        } else {
            sharedContext.alert(false, addCouponResponse.errors[0].message);
        }
        props.showLoading(false);
    }

    function removeCoupon(showAlert = true) {
        cartContext.setDiscountCoupon(null);
        setDiscountCoupon('');
        if (showAlert)
            sharedContext.alert(true, "Cupom removido");
    }

    return (
        <section className={`cart ${props.cartState ? "active" : ""}`}>
            <div className="cart-header">
                <div className="text-icon">
                    <i className="material-symbols-outlined">garden_cart</i>
                    <span>Meu carrinho ({cartContext.getTotalCartProducts()})</span>
                </div>
                <button className="btn btn-close-cart" onClick={() => props.setCartState(false)}>
                    <i className="material-icons-outlined">close</i>
                </button>
            </div>
            <div className="cart-content" >
                {cartContext.cartProducts.length > 0 ?
                    <>
                        <div className="cart-item-list">
                            {cartContext.cartProducts.map((x) => (
                                <div className="cart-item" key={x.id}>
                                    <img src={x.image} alt="" />
                                    <div className="cart-item-info">
                                        <span className="cart-item-info-top">
                                            {x.title}
                                            <button className="btn">
                                                <i className="material-symbols-outlined" onClick={() => removeProduct(x)}>delete</i>
                                            </button>
                                        </span>
                                        <span>Cor: {x.color}</span>
                                        <span>Tamanho: {x.size}</span>
                                        <span>Categoria: {x.category}</span>
                                        <span>Marca: {x.brand}</span>
                                        <span className="cart-item-info-bottom">
                                            <div className="cart-item-upgrade">
                                                <button className="btn" onClick={() => setCountToDown(x)}>
                                                    <span>-</span>
                                                </button>
                                                <span>{x.count}</span>
                                                <button className="btn" onClick={() => setCountToUp(x)}>
                                                    <span>+</span>
                                                </button>
                                            </div>
                                            {Price(x.price * x.count)}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="cart-footer" ref={myRef}>
                            <div className="cart-footer-inputs">
                                <div className="cart-input">
                                    <span>Cupom de desconto: </span>
                                    <div className="input-box">
                                        <input
                                            className="input-field light"
                                            type="text"
                                            placeholder="Cupom"
                                            disabled={cartContext.discountCoupon !== null}
                                            value={discountCoupon}
                                            onChange={e => setDiscountCoupon(e.target.value.toUpperCase())}
                                            style={cartContext.discountCoupon !== null ? {
                                                cursor: "not-allowed",
                                                pointerEvents: "none",
                                                background: "#e1e1e1",
                                                color: "#b0b0b0"
                                            } : {}}
                                        />
                                        {cartContext.discountCoupon !== null ?
                                            <a className="input-button light" href="#" onClick={() => removeCoupon()}>
                                                <i className="material-icons-outlined">close</i>
                                            </a>
                                            :
                                            <a className="input-button light" href="#" onClick={() => addCoupon()}>
                                                <i className="material-icons-outlined">send</i>
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="cart-footer-title">
                                <span className="footer-title">Resumo da compra</span>
                            </div>
                            <div className="cart-footer-content">
                                <div className="cart-footer-content-line">
                                    <span>Subtotal:</span>
                                    <span>{Price(cartContext.subTotal)}</span>
                                </div>
                                <div className="cart-footer-content-line">
                                    <span>Desconto</span>
                                    <span>- {Price(cartContext.discountCoupon != null ? ((cartContext.subTotal * cartContext.discountCoupon.discount) / 100) : 0)}</span>
                                </div>
                                <div className="cart-footer-content-line cart-total">
                                    <span>Total</span>
                                    <span>{Price(cartContext.total)}</span>
                                </div>
                            </div>
                            <Link className="btn btn-finalize" to={"/checkout/cliente"}>Finalizar compra</Link>
                        </div>
                    </>
                    :
                    <div className="empty-cart">
                        <div className="empty-cart-info">
                            <span>Parece que seu carrinho está vazio...</span>
                            <i className="material-symbols-outlined">garden_cart</i>
                            <span>Procurando sugestões?</span>
                            <span>Que tal...</span>
                            <Link className="btn btn-see-more" to={"/?ordem=best-seller"} onClick={() => props.setCartState(false)}>Ver mais vendidos</Link>
                        </div>
                    </div>}
            </div>
        </section>
    );
}
