import { useEffect } from "react";
import { AppSettings } from "../../settings/AppSettings";
import { maskedStoreCellPhone } from "../../helpers/PhoneHelper";

export function PrivacyPolicies() {
    useEffect(() => {
        window.scroll(0, 0);
    });
    return (
        <div className="information-screen">
            <div className="information-content">
                <h1>Política de Privacidade</h1>

                <h2>Introdução</h2>
                <p>Bem-vindo à <strong>Cangaço Store</strong>, sua privacidade é muito importante para nós. Esta Política de Privacidade explica como coletamos, usamos, divulgamos e protegemos suas informações quando você visita nosso site e utiliza nossos serviços. Por favor, leia esta política cuidadosamente. Ao acessar ou usar nosso site, você concorda com os termos desta Política de Privacidade.</p>

                <h2>Coleta de Informações</h2>
                <h3>Informações Pessoais</h3>
                <p>Coletamos informações pessoais que você nos fornece voluntariamente ao se registrar em nosso site, fazer uma compra, assinar nosso boletim informativo ou entrar em contato conosco. Essas informações podem incluir:</p>
                <ul>
                    <li>Nome</li>
                    <li>Endereço de e-mail</li>
                    <li>Endereço de entrega</li>
                    <li>Número de telefone</li>
                    <li>Número de identificação (CPF/CNPJ)</li>
                    <li>Informações de pagamento (cartão de crédito, débito, etc.)</li>
                    <li>Informações de conta (nome de usuário, senha, etc.)</li>
                </ul>

                <h3>Informações Automáticas</h3>
                <p>Também coletamos automaticamente certas informações sobre sua visita ao nosso site, incluindo:</p>
                <ul>
                    <li>Endereço IP</li>
                    <li>Tipo de navegador</li>
                    <li>Páginas visitadas</li>
                    <li>Horário e data de acesso</li>
                    <li>URL de referência</li>
                    <li>Dados de cookies</li>
                </ul>
                <h2>Uso das Informações</h2>
                <p>Utilizamos as informações que coletamos para:</p>
                <ul>
                    <li>Processar e gerenciar suas compras e pedidos</li>
                    <li>Enviar atualizações sobre o status do seu pedido</li>
                    <li>Melhorar a experiência do usuário em nosso site</li>
                    <li>Personalizar o conteúdo e as ofertas</li>
                    <li>Comunicar-se com você sobre novos produtos, serviços, promoções e eventos</li>
                    <li>Responder às suas perguntas e fornecer suporte ao cliente</li>
                    <li>Realizar análises e melhorar nosso site e serviços</li>
                </ul>

                <h2>Compartilhamento de Informações</h2>
                <p>Não vendemos, trocamos ou alugamos suas informações pessoais para terceiros. Podemos compartilhar suas informações com terceiros confiáveis para nos ajudar a operar nosso site e conduzir nossos negócios, desde que esses terceiros concordem em manter essas informações confidenciais. Exemplos incluem:</p>
                <ul>
                    <li>Provedores de serviços de pagamento</li>
                    <li>Empresas de transporte e entrega</li>
                    <li>Ferramentas de marketing e análise</li>
                    <li>Fornecedores de hospedagem de site</li>
                </ul>
                <h2>Segurança das Informações</h2>
                <p>Implementamos uma variedade de medidas de segurança para manter a segurança de suas informações pessoais. Utilizamos criptografia SSL (Secure Socket Layer) para proteger suas informações de pagamento durante a transmissão. Além disso, restringimos o acesso às suas informações pessoais a funcionários e prestadores de serviços que necessitam dessas informações para realizar suas funções.</p>

                <h2>Cookies</h2>
                <p>Nosso site usa cookies para melhorar sua experiência de navegação. Cookies são pequenos arquivos que um site ou seu provedor de serviços transfere para o disco rígido do seu computador através do navegador (se você permitir). Esses cookies permitem que o site reconheça seu navegador e, se aplicável, capture e lembre certas informações.</p>
                <p>Você pode optar por desativar os cookies através das configurações do seu navegador. No entanto, ao desativar os cookies, algumas partes do nosso site podem não funcionar corretamente.</p>

                <h2>Direitos dos Usuários</h2>
                <p>Você tem o direito de:</p>
                <ul>
                    <li>Acessar, corrigir ou excluir suas informações pessoais</li>
                    <li>Optar por não receber nossas comunicações de marketing</li>
                    <li>Solicitar a portabilidade de seus dados para outro provedor de serviços</li>
                    <li>Retirar seu consentimento para o uso de suas informações pessoais a qualquer momento</li>
                </ul>
                <p>Para exercer esses direitos, entre em contato conosco através do e-mail {AppSettings.StoreEmailAddress}.</p>

                <h2>Alterações à Política de Privacidade</h2>
                <p>Reservamo-nos o direito de atualizar ou modificar esta Política de Privacidade a qualquer momento. Notificaremos você sobre quaisquer alterações publicando a nova política em nosso site. Recomendamos que você revise esta política periodicamente para se manter informado sobre como estamos protegendo suas informações.</p>

                <h2>Contato</h2>
                <p>Se você tiver alguma dúvida ou preocupação sobre esta Política de Privacidade ou nossas práticas de privacidade, entre em contato conosco em:</p>
                <p>
                    Cangaço Store<br />
                    {AppSettings.StoreEmailAddress}<br />{
                    maskedStoreCellPhone()}</p>
            </div>
        </div>
    );
}