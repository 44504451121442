import { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { CheckoutEmail } from './CheckoutEmail';
import { CheckoutLogin } from './CheckoutLogin';
import { CheckoutSignup } from './CheckoutSignup';
import { CheckoutAddress } from './CheckoutAddress';
import { CheckoutPayment } from './CheckoutPayment';
import { CheckoutSuccess } from './CheckoutSuccess';
import { useHistory, useParams } from 'react-router-dom';
import './checkout-styles.css';
import { CreateTransactionSuccessData } from '../../contracts/SharedContracts';
import { CartContext } from '../../contexts/CartContext';
import { UserContext } from '../../contexts/UserContext';

interface Props {
    showLoading(value: boolean): void;
}

interface Params {
    step: string;
}

export function Checkout(props: Props) {
    const cartContext = useContext(CartContext);
    const userContext = useContext(UserContext);
    const history = useHistory();
    const { step } = useParams<Params>();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [document, setDocument] = useState('');
    const [birthdayAt, setBirthdayAt] = useState<Date>(null);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');

    const [successData, setSuccessData] = useState<CreateTransactionSuccessData>(null);

    useEffect(() => {
        window.scroll(0, 0);
        
        if (!cartContext.cartProducts || cartContext.cartProducts.length < 1) {
            history.replace("/");
            return;
        }

        if (!userContext.user) {
            history.replace("/checkout/cliente");
            return;
        }

        history.replace("/checkout/endereco");
    }, []);

    function resetFlow() {
        history.replace("/checkout/cliente");
        userContext.handleToken(null, null);
    }

    function renderStep() {
        switch (step) {
            case 'cliente':
                return <CheckoutEmail
                    {...props}
                    email={email}
                    setEmail={(value: string) => setEmail(value)}
                />;
            case 'login':
                return <CheckoutLogin
                    {...props}
                    email={email}
                    setEmail={(value: string) => setEmail(value)}
                    password={password}
                    setPassword={(value: string) => setPassword(value)}
                />;
            case 'cadastro-cliente':
                return <CheckoutSignup
                    {...props}
                    name={name}
                    email={email}
                    document={document}
                    birthdayAt={birthdayAt}
                    phone={phone}
                    password={password}
                    passwordConfirmation={passwordConfirmation}
                    setName={(value: string) => setName(value)}
                    setEmail={(value: string) => setEmail(value)}
                    setDocument={(value: string) => setDocument(value)}
                    setBirthdayAt={(value: Date) => setBirthdayAt(value)}
                    setPhone={(value: string) => setPhone(value)}
                    setPassword={(value: string) => setPassword(value)}
                    setPasswordConfirmation={(value: string) => setPasswordConfirmation(value)}
                />;
            case 'endereco':
                return <CheckoutAddress {...props} resetFlow={() => resetFlow()} />;
            case 'pagamento':
                return <CheckoutPayment {...props} setSuccessData={(value: CreateTransactionSuccessData) => setSuccessData(value)} resetFlow={() => resetFlow()} />;
            case 'sucesso':
                return <CheckoutSuccess {...props} successData={successData} />;
            case undefined:
            default:
                return (<><Redirect to={"/"}></Redirect></>);
        }
    }

    return (
        <div className="checkout">
            <div className="checkout-header">
                <div className="logo">
                    <a href="/">
                        <img src="/assets/images/favicon.png" width="35" height="35" alt="" />
                        <h1>Cangaço Store</h1>
                    </a>
                </div>
                <div className="checkout-header-info">
                    <span className="text-icon">
                        <i className="material-icons-outlined">verified_user</i>
                        Ambiente Seguro
                    </span>
                </div>
            </div>
            <div className="checkout-content">
                {renderStep()}
            </div>
        </div>
    );
}