import { createContext, useEffect, useState } from 'react';
import { AddressEntity, UserEntity } from '../entities/UserEntity';
import { AppSettings } from '../settings/AppSettings';
import { parseJwt } from '../helpers/JWTHelper';
import { AuthService } from '../services/AuthService';

export type UserContextType = {
    token: string;
    refreshToken: string;
    user: UserEntity;

    getActiveToSendAddress(): AddressEntity;
    handleRefreshToken(): Promise<void>;
    handleToken(token: string, refreshToen: string): void;
    logout(): void;
};

export const UserContext = createContext<UserContextType | null>(null);

interface Props {
    children: any
}

export function UserContextProvider(props: Props) {
    const [token, setToken] = useState('');
    const [refreshToken, setRefreshToken] = useState('');
    const [user, setUser] = useState<UserEntity>(null);

    useEffect(() => {
        var cartProductList = JSON.parse(localStorage.getItem(AppSettings.LocalStorageCartVarName));
        if (cartProductList === null)
            cartProductList = [];

        handleRefreshToken();
    }, []);

    async function logout() {
        handleToken();
        window.location.href = "/";
    }

    async function handleRefreshToken(): Promise<void> {
        const refreshToken = localStorage.getItem(AppSettings.LocalStorageRefreshTokenVarName);
        if (!refreshToken) {
            handleToken();
            return;
        }

        var response = await AuthService.getInstance().refreshToken(refreshToken);
        if (!response.success) {
            handleToken();
            return;
        }

        const userData = parseJwt(response.data.token);
        if (!userData) {
            localStorage.removeItem(AppSettings.LocalStorageTokenVarName);
            localStorage.removeItem(AppSettings.LocalStorageRefreshTokenVarName);
            setToken('');
            setRefreshToken('');
            setUser(null);

            return;
        }

        handleToken(response.data.token, response.data.refreshToken);
    }

    function handleToken(token: string = null, refreshToken: string = null): Promise<void> {
        if (!token || !refreshToken) {
            localStorage.removeItem(AppSettings.LocalStorageTokenVarName);
            localStorage.removeItem(AppSettings.LocalStorageRefreshTokenVarName);
            setToken('');
            setRefreshToken('');
            setUser(null);

            return;
        }

        const userData = parseJwt(token);
        if (!userData) {
            localStorage.removeItem(AppSettings.LocalStorageTokenVarName);
            localStorage.removeItem(AppSettings.LocalStorageRefreshTokenVarName);
            setToken('');
            setRefreshToken('');
            setUser(null);

            return;
        }

        localStorage.setItem(AppSettings.LocalStorageTokenVarName, token);
        localStorage.setItem(AppSettings.LocalStorageRefreshTokenVarName, refreshToken);
        setToken(token);
        setRefreshToken(refreshToken);
        setUser(userData);
    }

    function getActiveToSendAddress(): AddressEntity {
        if (!user || !user.addressList)
            return null;

        return user.addressList.find(x => x.activeToSend);
    }

    return (
        <UserContext.Provider
            value={{
                token,
                refreshToken,
                user,
                getActiveToSendAddress,
                handleRefreshToken,
                handleToken,
                logout
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
}