import { useEffect, useState } from "react";
import "./reviewForm.css";

interface Props {
    evaluate(comment: string, evaluation: number): void;
}

export function ReviewForm(props: Props) {
    const [message, setMessage] = useState('');
    const [evaluation, setEvaluation] = useState(0);
    const [showValidFields, setShowValidFields] = useState(false);

    useEffect(() => {
        resetForm();
    }, [window.location.href]);

    async function sendReview() {
        setShowValidFields(false);

        if (message.length == 0 || evaluation === 0) {
            setShowValidFields(true);
            return;
        }

        props.evaluate(message, evaluation);
    }

    function resetForm() {
        setMessage('');
        setEvaluation(0);
        setShowValidFields(false);
    }

    return (
        <form className="post-review-form form-box">
            <div className="form-content">
                <div className="title">
                    Deixe sua avaliação
                </div>
                <div className="form-input">
                    <span>Mensagem</span>
                    <div className="input-box">
                        <textarea
                            className={`textarea-field ${showValidFields && message === '' ? 'invalid' : ''}`}
                            placeholder="Deixe sua mensagem"
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            rows={4}
                        ></textarea>
                    </div>
                </div>
                <div className="form-input">
                    <span>Avaliação</span>
                    <div className="input-box">
                        <div className="stars-input">
                            <div className="review-buttons">
                                <button type="button" onClick={()=> setEvaluation(1)}>
                                    <i className={`star ${evaluation >= 1 ? "active" : ""} material-icons-outlined`}>star</i>
                                </button>
                                <button type="button" onClick={()=> setEvaluation(2)}>
                                    <i className={`star ${evaluation >= 2 ? "active" : ""} material-icons-outlined`}>star</i>
                                </button>
                                <button type="button" onClick={()=> setEvaluation(3)}>
                                    <i className={`star ${evaluation >= 3 ? "active" : ""} material-icons-outlined`}>star</i>
                                </button>
                                <button type="button" onClick={()=> setEvaluation(4)}>
                                    <i className={`star ${evaluation >= 4 ? "active" : ""} material-icons-outlined`}>star</i>
                                </button>
                                <button type="button" onClick={()=> setEvaluation(5)}>
                                    <i className={`star ${evaluation >= 5 ? "active" : ""} material-icons-outlined`}>star</i>
                                </button>
                            </div>
                            <span className="review-note">Nota: <strong>{evaluation}.0</strong></span>
                        </div>
                        {showValidFields && evaluation === 0 &&
                            <p className="error-message">Quantas estrelas você daria para este produto?</p>}
                    </div>
                </div>
                <div className="form-input">
                    <button className="btn btn-form" type="button" onClick={() => sendReview()}>Enviar</button>
                </div>
            </div>
        </form>
    );
}