import { CreateTransactionRequest, CreateTransactionResponse } from '../contracts/TransactionContracts';
import { BaseResponse } from '../entities/BaseResponse';
import { DiscountCouponEntity, TransactionEntity } from '../entities/TransactionEntity';
import { BaseService } from './BaseService';

export class TransactionService extends BaseService {
    static myInstance: TransactionService = null;

    static getInstance() {
        if (TransactionService.myInstance === null) {
            TransactionService.myInstance = new TransactionService();
        }

        return this.myInstance;
    }

    async getTransactions(): Promise<BaseResponse<TransactionEntity[]>> {
        return await super.get("/transaction/gettransactions", true);
    }

    async newTransaction(request: CreateTransactionRequest): Promise<BaseResponse<CreateTransactionResponse>> {
        return await super.post("/transaction/createtransaction", request);
    }

    async getByCode(code: string, document: string = null): Promise<BaseResponse<DiscountCouponEntity>> {
        const query = document && document.length > 0 ? `?document=${document}` : "";
        return await super.get("/transaction/getcouponbycode/" + code + query);
    }
}
