import { MouseEvent, useContext, useEffect, useState } from 'react';
import InputMask from "react-input-mask";
import { PaymentType } from '../../enums/PaymentType';
import { CheckoutProducts } from './CheckoutProducts';
import './checkout-styles.css';
import { AppSettings } from '../../settings/AppSettings';
import { MercadoPagoService } from '../../services/MercadoPagoService';
import { useHistory } from 'react-router-dom';
import { TransactionService } from '../../services/TransactionService';
import { Installment } from '../../contracts/MercadoPagoContracts';
import { CreateTransactionRequest } from '../../contracts/TransactionContracts';
import { CreateTransactionSuccessData } from '../../contracts/SharedContracts';
import { SharedContext } from '../../contexts/SharedContext';
import { CartContext } from '../../contexts/CartContext';
import { UserContext } from '../../contexts/UserContext';

interface Props {
    showLoading(value: boolean): void;
    resetFlow(): void;
    setSuccessData(value: CreateTransactionSuccessData): void;
}

export function CheckoutPayment(props: Props) {
    const sharedContext = useContext(SharedContext);
    const cartContext = useContext(CartContext);
    const userContext = useContext(UserContext);
    const history = useHistory();

    const [paymentType, setPaymentType] = useState<PaymentType>(null);
    const [identificationTypes, setIdentificationTypes] = useState([]);
    const [payerCosts, setPayerCosts] = useState<Installment[]>([]);
    const [isCardFromOther, setIsCardFromOther] = useState(false);
    const [isCardNumberInvalid, setIsCardNumberInvalid] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [cardName, setCardName] = useState('');
    const [cardDueDate, setCardDueDate] = useState('');
    const [cardAuthCode, setCardAuthCode] = useState('');
    const [identificationType, setIdentificationType] = useState('');
    const [document, setDocument] = useState('');
    const [payerCost, setPayerCost] = useState<string>('');
    const [issuerId, setIssuerId] = useState('');
    const [urlCardFlag, setUrlCardFlag] = useState('');
    const [bankPaymentId, setBankPaymentId] = useState('');
    const [showValidFields, setShowValidFields] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);
        getTypeIds();
    }, []);

    async function getTypeIds() {
        const typeIdsResult = await MercadoPagoService.getInstance().getIdentificationTypes();
        if (typeIdsResult.success) {
            setIdentificationTypes(typeIdsResult.data);
            setIdentificationType(typeIdsResult.data[0].id);
        }
    }

    function backToAddress() {
        history.goBack();
    }

    async function handleCardNumber(value: string) {
        // validating shippingType for getting all value replaced
        if (cartContext.shippingType === null && value.length > 0) {
            alert("Selecione primeiro uma opção de entrega.");
            return;
        }

        setCardNumber(value);

        if (value.length === 6 || value.length === 16) {
            const bin = value;
            const getPaymentResponse = await MercadoPagoService.getInstance().getPaymentMethods(bin);
            if (!getPaymentResponse.success) {
                setIsCardNumberInvalid(true);
                setPayerCosts([]);
                setPayerCost('');

                return;
            }

            setUrlCardFlag(getPaymentResponse.data.thumbnail);
            setBankPaymentId(getPaymentResponse.data.id);
            setIssuerId(getPaymentResponse.data.issuer.id);

            const getInstallmentsRequest = {
                amount: cartContext.total.toString(),
                locale: 'pt-BR',
                bin: bin,
                processingMode: 'aggregator'
            };
            const getInstallmentsResponse = await MercadoPagoService.getInstance().getInstallments(getInstallmentsRequest);
            if (getInstallmentsResponse.success) {
                setPayerCosts(getInstallmentsResponse.data.payer_costs);
                setPayerCost(getInstallmentsResponse.data.payer_costs[0].installments);
            }

            setIsCardNumberInvalid(false);
        }
    }

    function validateCreditCardForm() {
        if (isCardNumberInvalid)
            return false;

        if (!cardNumber || cardNumber.length !== 16 ||
            !cardName || cardName.length < 4 ||
            !cardDueDate || cardDueDate.length < 4 ||
            !cardAuthCode || cardAuthCode.length < 3)
            return false;

        if (!isCardFromOther)
            return true;

        if (!identificationType || identificationType.length < 3 ||
            !document || (document.length !== 11 && document.length !== 14))
            return false;

        return true;
    }

    async function finalizeTransaction() {
        if (paymentType === undefined || paymentType === null) {
            sharedContext.alert(false, "Necessário selecionar uma forma de pagamento.");
            return;
        }

        setShowValidFields(false);
        if (paymentType === PaymentType.CreditCard) {
            const validateResult = validateCreditCardForm();
            if (!validateResult) {
                setShowValidFields(true);
                sharedContext.alert(false, "Verifique os dados do cartão informado.");
                return;
            }
        }

        // setando itens e valores
        var createTransactionRequest = {
            transactionType: 1, // 1 é o tipo para saida de produtos
            shippingType: cartContext.shippingType.id,
            paymentType: paymentType,
            transactionItemList: cartContext.cartProducts,
            discountCoupon: cartContext.discountCoupon ? cartContext.discountCoupon.code : null,
            payment: null
        } as CreateTransactionRequest;

        // se o pagamento for cartão
        props.showLoading(true);
        if (paymentType === PaymentType.CreditCard) {
            const targetDocument = isCardFromOther ? document : userContext.user.document;
            const targetDocumentType = isCardFromOther ? identificationType : getDocumentType(targetDocument);

            var createtokenResponse = await MercadoPagoService.getInstance().createCardToken({
                cardNumber: cardNumber,
                cardholderName: cardName,
                cardExpirationMonth: cardDueDate.substring(0, 2),
                cardExpirationYear: `20${cardDueDate.substring(2)}`,
                securityCode: cardAuthCode,
                identificationType: targetDocumentType,
                identificationNumber: targetDocument
            });

            if (!createtokenResponse.success) {
                props.showLoading(false);
                setShowValidFields(true);
                sharedContext.alert(false, "Verifique os dados do cartão informado.");

                return;
            }
            
            createTransactionRequest.payment = {
                bankInstallments: Number(payerCost),
                bankIssuerId: issuerId.toString(),
                bankPaymentMethodId: bankPaymentId,
                token: createtokenResponse.data.id,
                payer: {
                    documentType: targetDocumentType,
                    document: targetDocument,
                }
            }
        }

        // DEBUG LINE ****&*
        // this.props.showLoading(false);
        // console.log(createTransactionRequest);
        // return;
        // ****&* ****&* ****&*

        // send all data
        var newTransactionResponse = await TransactionService.getInstance().newTransaction(createTransactionRequest);
        // this.setState({ proccessFinished: true, responseNewTransaction: newTransactionResponse });

        props.showLoading(false);

        if (!newTransactionResponse.success) {
            if (newTransactionResponse.errors[0] && newTransactionResponse.errors[0].code === "401") {
                sharedContext.alert(false, "Tempo para finalização excedido, necessário realizar o login novamente.");
                props.resetFlow();
                return;
            }

            sharedContext.alert(false, newTransactionResponse.errors[0].message);
            return;
        }

        localStorage.removeItem(AppSettings.LocalStorageCartVarName);
        props.setSuccessData({
            cartProducts: cartContext.cartProducts,
            shippingType: cartContext.shippingType,
            subTotalValue: cartContext.subTotal,
            paymentType: paymentType,
            totalValue: cartContext.total,
            discountCoupon: cartContext.discountCoupon,
            paymentResponse: newTransactionResponse.data
        });
        cartContext.updateCartProducts(null);
        cartContext.setShippingType(null);
        cartContext.setDiscountCoupon(null);

        history.replace("/checkout/sucesso");
        return;
    }

    function getDocumentType(document: string) {
        var type = "CPF";

        if (document && document.length === 14)
            type = "CNPJ";

        return type;
    }

    const handleColapse = (event: MouseEvent, paymentType: PaymentType) => {
        const target = event.currentTarget;
        const btnColapseHandlerList = window.document.querySelectorAll(".colapse-handler");
        const colapseContentList = window.document.querySelectorAll(".colapse-content");

        btnColapseHandlerList.forEach(x => x.classList.remove("active"));
        colapseContentList.forEach(x => x.classList.remove("active"));
        target.classList.add("active");

        var colapseContent = window.document.querySelector(`.colapse-content[title='${target.getAttribute("title")}']`);
        colapseContent.classList.add("active");

        setPaymentType(paymentType);
    }

    return (
        <div className="checkout-wrap">
            <div className="checkout-column">
                <div className="form-box">
                    <div className="form-content">
                        <div className="payment-title">
                            <span>Selecione a forma de pagamento:</span>
                        </div>
                        <div className="payment-list">
                            {/* <div className="payment-item">
                                <div className="payment-item-content colapse-handler" title="onTake" onClick={(e) => handleColapse(e, PaymentType.PaymentOnTakeOff)}>
                                    <input
                                        className="payment-item-input"
                                        type="radio"
                                        name="payment-item"
                                        checked={paymentType === PaymentType.PaymentOnTakeOff}
                                        readOnly
                                    />
                                    <span className="radio-item-title">Pagar na entrega</span>
                                </div>
                                <div className="colapse-content" title="onTake">
                                    <div className="payment-info">
                                        <p>Clique em concluir e realize o pagamento na entrega.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-item">
                                <div className="payment-item-content colapse-handler" title="boleto" onClick={(e) => handleColapse(e, PaymentType.BankTicket)}>
                                    <input
                                        className="payment-item-input"
                                        type="radio"
                                        name="payment-item"
                                        checked={paymentType === PaymentType.BankTicket}
                                        readOnly
                                    />
                                    <span className="radio-item-title">Boleto</span>
                                </div>
                                <div className="colapse-content" title="boleto">
                                    <div className="payment-info">
                                        <p>Clique em concluir e você receberá o boleto emitido para realizar o pagamento no valor de R$ {cartContext.total.toFixed(2)}</p>
                                        <p>Quando seu pagamento for confirmado você receberá um e-mail informativo.</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="payment-item">
                                <div className="payment-item-content colapse-handler" title="pix" onClick={(e) => handleColapse(e, PaymentType.PixTransfer)}>
                                    <input
                                        className="payment-item-input"
                                        type="radio"
                                        name="payment-item"
                                        checked={paymentType === PaymentType.PixTransfer}
                                        readOnly
                                    />
                                    <span className="radio-item-title">PIX</span>
                                </div>
                                <div className="colapse-content" title="pix">
                                    <div className="payment-info">
                                        <p>Clique em concluir e você receberá a chave pix para realizar o pagamento no valor de R$ {cartContext.total.toFixed(2)}</p>
                                        <p>Quando sua transferência for confirmada você receberá um e-mail informativo.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="payment-item">
                                <div className="payment-item-content colapse-handler" title="cartao" onClick={(e) => handleColapse(e, PaymentType.CreditCard)}>
                                    <input
                                        className="payment-item-input"
                                        type="radio"
                                        name="payment-item"
                                        checked={paymentType === PaymentType.CreditCard}
                                        readOnly
                                    />
                                    <span className="radio-item-title">Cartão de crédito</span>
                                </div>
                                <div className="colapse-content" title="cartao">
                                    <div className="payment-info">
                                        <p>Informe os dados do seu cartão e clique em Finalizar.</p>
                                    </div>
                                    <form className="form-box" action="">
                                        <div className="form-content">
                                            <div className="form-input">
                                                <span>Número do cartão</span>
                                                <div className="input-box">
                                                    <InputMask
                                                        className={`input-field light ${isCardNumberInvalid || (showValidFields && cardNumber === '') ? 'invalid' : ''}`}
                                                        type="text"
                                                        placeholder="Número do cartão"
                                                        mask="9999-9999-9999-9999"
                                                        value={cardNumber}
                                                        onChange={e => handleCardNumber(e.target.value.replace(/[^a-z0-9]/gi, ''))}
                                                    />
                                                    <button className="input-button btn light" type="button" style={{ left: "calc(100% - 44px)" }}>
                                                        {urlCardFlag.length > 0 && <img src={urlCardFlag} width="30" alt="urlCardFlag" />}
                                                    </button>
                                                </div>
                                                {isCardNumberInvalid && <p style={{ color: "#ff4e4e" }}>Numéro do cartão inválido</p>}
                                            </div>
                                            <div className="form-input">
                                                <span>Nome Impresso no cartão</span>
                                                <div className="input-box">
                                                    <input
                                                        className={`input-field light ${showValidFields && cardName === '' ? 'invalid' : ''}`}
                                                        type="text"
                                                        placeholder="Digite o Nome"
                                                        value={cardName}
                                                        onChange={(e) => setCardName(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="inline-fields">
                                                <div className="form-input">
                                                    <span>Dt Vencimento</span>
                                                    <div className="input-box">
                                                        <InputMask
                                                            className={`input-field light ${showValidFields && cardDueDate === '' ? 'invalid' : ''}`}
                                                            type="text"
                                                            placeholder="07/35"
                                                            mask="99/99"
                                                            value={cardDueDate}
                                                            onChange={e => setCardDueDate(e.target.value.replace(/[^a-z0-9]/gi, ''))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-input">
                                                    <span>CVV</span>
                                                    <div className="input-box">
                                                        <InputMask
                                                            className={`input-field light ${showValidFields && cardAuthCode === '' ? 'invalid' : ''}`}
                                                            type="text"
                                                            placeholder="CVV"
                                                            mask=""
                                                            value={cardAuthCode}
                                                            onChange={e => setCardAuthCode(e.target.value.replace(/[^a-z0-9]/gi, ''))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {payerCosts.length > 0 &&
                                                < div className="form-input">
                                                    <span>Parcelamento</span>
                                                    <div className="input-box">
                                                        <select
                                                            className={`input-field select light ${showValidFields && payerCost === '' ? 'invalid' : ''}`}
                                                            name="installment"
                                                            value={payerCost}
                                                            onChange={e => setPayerCost(e.target.value)}
                                                        >
                                                            {payerCosts.map(x => (
                                                                <option key={x.installments} value={x.installments}>{x.recommended_message}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            }
                                            <div className="form-input">
                                                <div className="checkbox" onClick={() => setIsCardFromOther(!isCardFromOther)}>
                                                    <input
                                                        className={`input-field checkbox`}
                                                        type="checkbox"
                                                        checked={isCardFromOther}
                                                        readOnly
                                                    />
                                                    <span>O cartão pertence a outra pessoa?</span>
                                                </div>
                                            </div>
                                            {isCardFromOther &&
                                                <div className="inline-fields break">
                                                    <div className="form-input">
                                                        <span>Tipo</span>
                                                        <div className="input-box">
                                                            <select
                                                                className={`input-field select light ${showValidFields && identificationType === '' ? 'invalid' : ''}`}
                                                                name="installment"
                                                                value={identificationType}
                                                                onChange={e => setIdentificationType(e.target.value)}
                                                            >
                                                                {identificationTypes.map(x => (
                                                                    <option key={x.id} value={x.id}>{x.id}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-input">
                                                        <span>Número Documento do Titular</span>
                                                        <div className="input-box">
                                                            <InputMask
                                                                className={`input-field light ${showValidFields && document === '' ? 'invalid' : ''}`}
                                                                type="text"
                                                                placeholder={"Digite o " + identificationType}
                                                                mask={identificationType === 'CNPJ' ? "99.999.999/9999-99" : "999.999.999-99"}
                                                                value={document}
                                                                onChange={e => setDocument(e.target.value.replace(/[^a-z0-9]/gi, ''))}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="checkout-buttons">
                            <div className="inline-buttons">
                                <button className="btn btn-form light" type="button" onClick={() => backToAddress()}>Voltar</button>
                                <button className="btn btn-form text-icon" type="button" onClick={() => finalizeTransaction()}>
                                    Finalizar
                                    <i className="material-icons-outlined">arrow_forward</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className="checkout-column cart-items">
                <CheckoutProducts
                    cartProducts={cartContext.cartProducts}
                    shippingType={cartContext.shippingType}
                    discountCoupon={cartContext.discountCoupon}
                    subTotal={cartContext.subTotal}
                    total={cartContext.total}
                />
            </div>
        </div >
    );
}