import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SharedContext } from '../../contexts/SharedContext';
import { UserContext } from '../../contexts/UserContext';
import { AuthService } from '../../services/AuthService';

interface Props {
    showLoading(value: boolean): void;
}

export function Login(props: Props) {
    const sharedContext = useContext(SharedContext);
    const userContext = useContext(UserContext);
    const history = useHistory();
    
    const [showValidFields, setShowValidFields] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('',);
    const [password, setPassword] = useState('');

    useEffect(() => {
        // ...
    }, []);

    async function tryLogin() {
        setShowValidFields(true);
        if (email === '' || password === '') {
            sharedContext.alert(false, "Informe e-mail e senha para fazer o login.");
            return;
        }

        props.showLoading(true);
        var loginResponse = await AuthService.getInstance().login(email, password);
        if (!loginResponse.success) {
            sharedContext.alert(false, loginResponse.errors[0].message);
            props.showLoading(false);
            return;
        }

        userContext.handleToken(loginResponse.data.token, loginResponse.data.refreshToken);

        props.showLoading(false);
        history.replace("/minha-conta/pedidos");
    }

    return (
        <section className="form-container">
            <div className="form">
                <form className="form-box" action="">
                    <div className="form-content">
                        <div className="logo-box">
                            <div className="logo">
                                <a href="/">
                                    <img src="/assets/images/favicon.png" width="35" height="35" alt="" />
                                    <h1>Cangaço Store</h1>
                                </a>
                            </div>
                        </div>
                        <div className="title">
                            Faça seu login
                        </div>
                        <div className="form-input">
                            <span>E-mail</span>
                            <div className="input-box">
                                <input
                                    className={`input-field light ${showValidFields && email === '' ? 'invalid' : ''}`}
                                    type="email"
                                    placeholder="Digite seu e-mail"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <span>Senha</span>
                            <div className="input-box">
                                <input
                                    className={`input-field light ${showValidFields && password === '' ? 'invalid' : ''}`}
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Digite sua senha"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                <button className="input-button btn light" type="button" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <i className="material-icons-outlined">visibility_on</i> : <i className="material-icons-outlined">visibility_off</i>}
                                </button>
                            </div>
                        </div>
                        <div className="form-input">
                            <button type="button" className="btn btn-form" onClick={() => tryLogin()}>Entrar</button>
                        </div>
                        <div className="form-links">
                            <Link to={"/esqueci-minha-senha"}>Esqueceu sua senha?</Link>
                        </div>
                        <div className="form-links">
                            <Link to={"/cadastro"}>É novo por aqui? Crie sua conta.</Link>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
