import InputMask from "react-input-mask";
import { State } from '../../enums/State';
import { CheckoutProducts } from './CheckoutProducts';
import { Price } from '../../helpers/PriceHelper';
import './checkout-styles.css';
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AddressService } from "../../services/AddressService";
import { UserService } from "../../services/UserService";
import { AddressEntity } from "../../entities/UserEntity";
import { SharedContext } from "../../contexts/SharedContext";
import { UserContext } from "../../contexts/UserContext";
import { CartContext } from "../../contexts/CartContext";

interface Props {
    showLoading(value: boolean): void;
    resetFlow(): void;
}

export function CheckoutAddress(props: Props) {
    const sharedContext = useContext(SharedContext);
    const userContext = useContext(UserContext);
    const cartContext = useContext(CartContext);
    const history = useHistory();

    const [updateFormState, setUpdateFormState] = useState(false);
    const [createFormState, setCreateFormState] = useState(false);
    const [showValidFields, setShowValidFields] = useState(false);
    const [id, setId] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [district, setDistrict] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [state, setState] = useState('AC');
    const [activeToSend, setActiveToSend] = useState(false);

    useEffect(() => {
        onStartingComponent();
    }, []);

    async function onStartingComponent() {
        window.scroll(0, 0);

        if (!cartContext.cartProducts || cartContext.cartProducts.length < 1)
            history.replace("/");

        if (!userContext.user || !userContext.user.addressList)
            history.replace("/");

        const addressSelected = userContext.getActiveToSendAddress();
        if (!addressSelected) {
            setCreateFormState(true);
            setActiveToSend(true);
            sharedContext.setShippingTypeList([]);

            return;
        }

        props.showLoading(true);
        await sharedContext.checkShipping(addressSelected.zipCode);
        props.showLoading(false);
    }

    function clearForm() {
        var validAddressExists = userContext.getActiveToSendAddress();

        setUpdateFormState(false);
        setCreateFormState(false);
        setShowValidFields(false);
        setId('');
        setZipCode('');
        setAddress('');
        setCity('');
        setDistrict('');
        setNumber('');
        setComplement('');
        setState('AC');
        setActiveToSend(validAddressExists ? false : true);
    }

    async function handleZipCode(value: string) {
        setZipCode(value);

        if (value && value.length === 8) {
            props.showLoading(true);
            var addressResponse = await AddressService.getInstance().getAddress(value);
            if (!addressResponse.erro) {
                setAddress(addressResponse.logradouro);
                setDistrict(addressResponse.bairro);
                setCity(addressResponse.localidade);
                setState(State.GetStateByCode(addressResponse.uf).value);
            }

            props.showLoading(false);
        }
    }

    function handleCreateFormState() {
        const actualUpdateState = updateFormState;
        const actualCreateState = createFormState;
        clearForm();

        setUpdateFormState(actualUpdateState ? false : actualUpdateState);
        setCreateFormState(!actualCreateState);
    }

    function handleUpdateFormState(address: AddressEntity) {
        const actualState = updateFormState;
        const addressId = id;
        clearForm();

        if (actualState && addressId === address.id)
            return;

        setUpdateFormState(true);
        setCreateFormState(createFormState ? false : createFormState);
        setId(address.id);
        setZipCode(address.zipCode);
        setAddress(address.address);
        setCity(address.city);
        setDistrict(address.district);
        setNumber(address.number);
        setComplement(address.complement);
        setState(address.state);
        setActiveToSend(address.activeToSend);
    }

    function proccedToPayment() {
        const addressSelected = userContext.getActiveToSendAddress();
        if (!addressSelected) {
            sharedContext.alert(false, "Necessário informar um endereço válido para continuar.");
            return;
        }

        if (!cartContext.shippingType) {
            sharedContext.alert(false, "Necessário informar uma forma de entrega para continuar.");
            return;
        }

        history.push("/checkout/pagamento");
        window.scrollTo(0, 0);
    }

    async function handleAddress(address: AddressEntity) {
        if (address.activeToSend)
            return;

        props.showLoading(true);
        const response = await UserService.getInstance().activateAddress(address.id);
        if (!response.success) {
            props.showLoading(false);
            if (response.errors[0] && response.errors[0].code === "401") {
                sharedContext.alert(false, "Tempo para finalização excedido, necessário realizar o login novamente.");
                props.resetFlow();
            }

            return;
        }

        var user = userContext.user;
        user.addressList.forEach(x => {
            x.activeToSend = (x.id === address.id);
        });

        await sharedContext.checkShipping(address.zipCode);
        cartContext.setShippingType(null);
        props.showLoading(false);
    }

    function isAddressChecked(id: string) {
        if (!userContext.user || !userContext.user.addressList)
            return false;

        var address = userContext.getActiveToSendAddress();
        if (!address)
            return false;

        return address.id === id;
    }

    async function sendAddress(update: boolean) {
        setShowValidFields(false);
        if (zipCode === '' || address === '' || city === '' || district === '' || number === '' || complement === '' || state === '') {
            sharedContext.alert(false, "Preencha todos os campos para continuar.");
            setShowValidFields(true);
            return;
        }

        props.showLoading(true);
        var addressToSend = {
            zipCode: zipCode,
            address: address,
            city: city,
            district: district,
            number: number,
            complement: complement,
            state: state,
            activeToSend: activeToSend
        } as AddressEntity;

        var response = null;
        if (update) {
            addressToSend.id = id;
            response = await UserService.getInstance().updateAddress(addressToSend);
        }
        else {
            response = await UserService.getInstance().createAddress(addressToSend);
        }

        if (!response.success) {
            props.showLoading(false);
            if (response.errors[0] && response.errors[0].code === "401") {
                sharedContext.alert(false, "Tempo para finalização excedido, necessário realizar o login novamente.");
                props.resetFlow();
                return;
            }

            sharedContext.alert(false, response.errors[0].message);
            return;
        }

        await userContext.handleRefreshToken();

        if (activeToSend)
            await sharedContext.checkShipping(zipCode);

        sharedContext.alert(true, `Endereço ${update ? "atualizado" : "criado"} com sucesso!`);
        const myTimeout = setTimeout(() => {
            setCreateFormState(false);
            setUpdateFormState(false);

            window.scrollTo(0, 0);
            props.showLoading(false);

            clearTimeout(myTimeout);
        }, 2000);
    }

    return (
        <div className="checkout-wrap">
            <div className="checkout-column">
                <div className="form-box">
                    <div className="form-content">
                        <div className="checkout-address-title">
                            Selecione onde deseja receber suas compras:
                        </div>
                        <div className="address-list">
                            {userContext.user && userContext.user.addressList && userContext.user.addressList.map(x => (
                                <div className="address-item" key={x.id}>
                                    <div className="address-item-content">
                                        <input
                                            className="address-item-input"
                                            type="radio"
                                            name="address-item"
                                            checked={isAddressChecked(x.id)}
                                            readOnly
                                        />
                                        <div>
                                            <div onClick={() => handleAddress(x)}>
                                                <span className="radio-item-title">{x.address}, {x.number}</span>
                                                <span className="radio-item-info">{x.district} - {x.city}, CE - CEP {x.zipCode}</span>
                                            </div>
                                            <button className="change-address-item" type="button" onClick={() => handleUpdateFormState(x)}>Alterar</button>
                                        </div>
                                    </div>
                                    {updateFormState && id === x.id &&
                                        <div className="address-item-form">
                                            <form className="form-box" action="">
                                                <div className="form-content">
                                                    <div className="form-input">
                                                        <span>CEP</span>
                                                        <div className="input-box">
                                                            <InputMask
                                                                className={`input-field light ${showValidFields && zipCode === '' ? 'invalid' : ''}`}
                                                                type="text"
                                                                placeholder="Digite seu CEP"
                                                                mask="99999-999"
                                                                value={zipCode}
                                                                onChange={(e) => handleZipCode(e.target.value.replace(/[^a-z0-9]/gi, ''))}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-input">
                                                        <span>Endereço</span>
                                                        <div className="input-box">
                                                            <input
                                                                className={`input-field light ${showValidFields && address === '' ? 'invalid' : ''}`}
                                                                type="text"
                                                                placeholder="Digite seu Endereço"
                                                                value={address}
                                                                onChange={(e) => setAddress(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-input">
                                                        <span>Cidate</span>
                                                        <div className="input-box">
                                                            <input
                                                                className={`input-field light ${showValidFields && city === '' ? 'invalid' : ''}`}
                                                                type="text"
                                                                placeholder="Digite a cidade"
                                                                value={city}
                                                                onChange={(e) => setCity(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-input">
                                                        <span>Bairro</span>
                                                        <div className="input-box">
                                                            <input
                                                                className={`input-field light ${showValidFields && district === '' ? 'invalid' : ''}`}
                                                                type="text"
                                                                placeholder="Digite o bairro"
                                                                value={district}
                                                                onChange={(e) => setDistrict(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="inline-fields">
                                                        < div className="form-input">
                                                            <span>Estado</span>
                                                            <div className="input-box">
                                                                <select
                                                                    className={`input-field light ${showValidFields && state === '' ? 'invalid' : ''}`}
                                                                    name="state"
                                                                    value={state}
                                                                    onChange={(e) => setState(e.target.value)}
                                                                >
                                                                    {State.data.map(x => (
                                                                        <option key={x.value} value={x.value}>{x.label}</option>

                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="form-input">
                                                            <span>Número</span>
                                                            <div className="input-box">
                                                                <input
                                                                    className={`input-field light ${showValidFields && number === '' ? 'invalid' : ''}`}
                                                                    type="text"
                                                                    placeholder="Digite o número"
                                                                    value={number}
                                                                    onChange={(e) => setNumber(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-input">
                                                        <span>Complemento</span>
                                                        <div className="input-box">
                                                            <input
                                                                className={`input-field light ${showValidFields && complement === '' ? 'invalid' : ''}`}
                                                                type="text"
                                                                placeholder="Digite o complemento"
                                                                value={complement}
                                                                onChange={(e) => setComplement(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-input">
                                                        <div className="inline-buttons">
                                                            <button className="btn btn-form light" type="button" onClick={() => handleUpdateFormState(x)}>Cancelar</button>
                                                            <button className="btn btn-form" type="button" onClick={() => sendAddress(true)}>Enviar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    }
                                </div>
                            ))}

                            {createFormState ?
                                <div className="address-item">
                                    <div className="address-item-content">
                                        <span className="radio-item-title">Novo Endereço</span>
                                    </div>
                                    <div className="address-item-form">
                                        <form className="form-box" action="">
                                            <div className="form-content">
                                                <div className="form-input">
                                                    <span>CEP</span>
                                                    <div className="input-box">
                                                        <InputMask
                                                            className={`input-field light ${showValidFields && zipCode === '' ? 'invalid' : ''}`}
                                                            type="text"
                                                            placeholder="Digite seu CEP"
                                                            mask="99999-999"
                                                            value={zipCode}
                                                            onChange={(e) => handleZipCode(e.target.value.replace(/[^a-z0-9]/gi, ''))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-input">
                                                    <span>Endereço</span>
                                                    <div className="input-box">
                                                        <input
                                                            className={`input-field light ${showValidFields && address === '' ? 'invalid' : ''}`}
                                                            type="text"
                                                            placeholder="Digite seu Endereço"
                                                            value={address}
                                                            onChange={(e) => setAddress(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-input">
                                                    <span>Cidate</span>
                                                    <div className="input-box">
                                                        <input
                                                            className={`input-field light ${showValidFields && city === '' ? 'invalid' : ''}`}
                                                            type="text"
                                                            placeholder="Digite a cidade"
                                                            value={city}
                                                            onChange={(e) => setCity(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-input">
                                                    <span>Bairro</span>
                                                    <div className="input-box">
                                                        <input
                                                            className={`input-field light ${showValidFields && district === '' ? 'invalid' : ''}`}
                                                            type="text"
                                                            placeholder="Digite o bairro"
                                                            value={district}
                                                            onChange={(e) => setDistrict(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="inline-fields">
                                                    < div className="form-input">
                                                        <span>Estado</span>
                                                        <div className="input-box">
                                                            <select
                                                                className={`input-field light ${showValidFields && state === '' ? 'invalid' : ''}`}
                                                                name="state"
                                                                value={state}
                                                                onChange={(e) => setState(e.target.value)}
                                                            >
                                                                {State.data.map(x => (
                                                                    <option key={x.value} value={x.value}>{x.label}</option>

                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="form-input">
                                                        <span>Número</span>
                                                        <div className="input-box">
                                                            <input
                                                                className={`input-field light ${showValidFields && number === '' ? 'invalid' : ''}`}
                                                                type="text"
                                                                placeholder="Digite o número"
                                                                value={number}
                                                                onChange={(e) => setNumber(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-input">
                                                    <span>Complemento</span>
                                                    <div className="input-box">
                                                        <input
                                                            className={`input-field light ${showValidFields && complement === '' ? 'invalid' : ''}`}
                                                            type="text"
                                                            placeholder="Digite o complemento"
                                                            value={complement}
                                                            onChange={(e) => setComplement(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-input">
                                                    <div className="checkbox" onClick={() => setActiveToSend(userContext.getActiveToSendAddress() ? !activeToSend : activeToSend)}>
                                                        <input
                                                            className={`input-field checkbox`}
                                                            type="checkbox"
                                                            checked={activeToSend}
                                                            disabled={!userContext.getActiveToSendAddress()}
                                                            readOnly
                                                        />
                                                        <span>Utilizar este endereço?</span>
                                                    </div>
                                                </div>
                                                <div className="form-input">
                                                    <div className="inline-buttons">
                                                        <button className="btn btn-form light" type="button" onClick={() => handleCreateFormState()}>Cancelar</button>
                                                        <button className="btn btn-form" type="button" onClick={() => sendAddress(false)}>Enviar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                :
                                <button className="btn btn-form create-address-btn" type="button" onClick={() => handleCreateFormState()}>Criar endereço</button>
                            }
                        </div>
                        {sharedContext.shippingTypeList && sharedContext.shippingTypeList.length > 0 &&
                            <>
                                <div className="checkout-address-title">
                                    <span>
                                        Escolha a forma de entrega:
                                    </span>
                                </div>
                                <div className="shipping-list">
                                    {sharedContext.shippingTypeList && sharedContext.shippingTypeList.map(x => (
                                        <div className="shipping-item" key={x.id} onClick={() => cartContext.setShippingType(x)}>
                                            <div className="content">
                                                <input
                                                    className="shipping-item-input"
                                                    type="radio"
                                                    name="shipping-item"
                                                    checked={cartContext.shippingType && cartContext.shippingType.id === x.id ? true : false}
                                                    readOnly
                                                />
                                                <div>
                                                    <span className="radio-item-title">{x.title}</span>
                                                    <span className="radio-item-info">{Price(x.value)} - {x.days > 1 ? `Até ${x.days} dias úteis` : `Até ${x.days} dia útil`}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        }
                        <div className="checkout-buttons">
                            <div className="inline-buttons">
                            <button className="btn btn-form light" type="button" onClick={() => window.location.href = "/"}>Voltar</button>
                                <button className="btn btn-form text-icon" type="button" onClick={() => proccedToPayment()}>
                                    Continuar
                                    <i className="material-icons-outlined">arrow_forward</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="checkout-column cart-items">
                <CheckoutProducts
                    cartProducts={cartContext.cartProducts}
                    subTotal={cartContext.subTotal}
                    shippingType={cartContext.shippingType}
                    discountCoupon={cartContext.discountCoupon}
                    total={cartContext.total}
                />
            </div>
        </div>
    );
}