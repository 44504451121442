import { useContext, useEffect, useRef, useState, MouseEvent } from 'react';
import { ProductService } from '../../services/ProductService';
import { Product } from '../../components/products/Product';
import Slider from 'react-slick';
import { Price } from '../../helpers/PriceHelper';
import "./product-details-styles.css";
import { CheckSize } from '../../components/checkSize/CheckSize';
import { ProductEntity, SkuEntity } from '../../entities/ProductEntity';
import { SharedContext } from '../../contexts/SharedContext';
import { CartContext } from '../../contexts/CartContext';
import { ReviewForm } from '../../components/reviewForm/reviewForm';
import { CreateReviewRequest } from '../../contracts/ProductContracts';
import { UserContext } from '../../contexts/UserContext';

interface Props {
    showLoading(value: boolean): void;
    setNoScroll(value: boolean): void;
    productUrl: string;
}

export function ProductDetails(props: Props) {
    const sharedContext = useContext(SharedContext);
    const cartContext = useContext(CartContext);
    const userContext = useContext(UserContext);
    const myRef: any = useRef();

    const [showModal, setShowModal] = useState(false);
    const [product, setProduct] = useState<ProductEntity>(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [selectedSku, setSelectedSku] = useState<SkuEntity>({ id: 0, size: '', weight: 0, color: '', stock: 0, urlImg: '', productId: 0 } as SkuEntity);
    const [selectedCount, setSelectedCount] = useState(1);
    const [canEvaluate, setCanEvaluate] = useState(false);
    // const [url, setUrl] = useState('');

    useEffect(() => {
        window.scroll(0, 0);
        // setUrl(props.productUrl);
        getProduct();
    }, [window.location.href]);

    async function getProduct() {
        const productService = ProductService.getInstance();
        props.showLoading(true);
        const { productUrl } = props;
        const productRerponse = await productService.getByUrl(productUrl);
        props.showLoading(false);
        if (!productRerponse.success) {
            setProduct(null);
            return;
        }

        setProduct(productRerponse.data);
        setSelectedSku(productRerponse.data.skuList[0]);
        setSelectedCount(1);
        props.showLoading(false);

        const filters: any = { limit: '4', categories: [productRerponse.data.category.url] };
        const queryString = "?" + Object.keys(filters).map(key => filters[key] != null && filters[key].length > 0 ? key + '=' + filters[key] : "").filter((x) => x.length > 0).join('&');

        const relatedProducts = await productService.getAllProducts(queryString)
        if (relatedProducts.success && relatedProducts.data.items.length > 0) {
            var newList = relatedProducts.data.items.filter((x) => x.id !== productRerponse.data.id);
            setRelatedProducts(newList);
        }

        if (!userContext.user)
            return;

        const checkIsProductBought = await productService.isProductBoughtByUser(productRerponse.data.id);
        setCanEvaluate(checkIsProductBought.success);
    }

    // componentDidUpdate() {
    //     if (redirect)
    //         this.setState({
    //             redirect: false
    //         });

    //     if (url !== props.productUrl) {
    //         window.scrollTo(0, 0);

    //         props.showLoading(true);
    //         this.getProduct();

    //         this.setState({
    //             url: props.productUrl
    //         });
    //     }

    // }

    function setCountToUp() {
        if (product) {
            if (selectedSku !== null && selectedCount < selectedSku.stock)
                setSelectedCount(selectedCount + 1);
        } else {
            setSelectedCount(0);
        }
    }

    function setCountToDown() {
        if (product) {
            if (selectedSku !== null && selectedCount > 1)
                setSelectedCount(selectedCount - 1);
        } else {
            setSelectedCount(0);
        }
    }

    const selectSize = (size: string) => {
        // check if find same size and same color
        var newSku = product.skuList.find(x => x.size === size && x.color === selectedSku.color);

        // if not, check if find same size and diferent color
        if (!newSku)
            newSku = product.skuList.find(x => x.size === size && x.color !== selectedSku.color);

        // if not, still in the same
        if (!newSku)
            return;

        // if yes change sku
        setSelectedSku(newSku);
        setSelectedCount(newSku.stock > 0 ? 1 : 0);
    }

    const selectColor = (color: string, i: number) => {
        myRef.current.slickGoTo(i);

        // check if find same color and same size
        var newSku = product.skuList.find(x => x.size === selectedSku.size && x.color === color);

        // if not, check if find same color and diferent size
        if (!newSku)
            newSku = product.skuList.find(x => x.size !== selectedSku.size && x.color === color);

        // if not, still in the same
        if (!newSku)
            return;

        // if yes change sku
        setSelectedSku(newSku);
        setSelectedCount(newSku.stock > 0 ? 1 : 0);
    }

    function addToCart(openCart = false) {
        if (selectedSku.stock < 1) {
            sharedContext.alert(false, "Este tamanho e cor não está mais disponível, tente outra opção.");
            return;
        }

        cartContext.addCartProduct({
            id: selectedSku.id,
            title: product.title,
            url: product.url,
            price: Number(product.price),
            color: selectedSku.color,
            size: selectedSku.size,
            stock: selectedSku.stock,
            count: selectedCount,
            image: selectedSku.urlImg,
            category: product.category.title,
            brand: product.brand.title
        });

        sharedContext.setShowCart(openCart);
    }

    function renderSize() {
        var newArray: string[] = [];
        if (!product.skuList && product.skuList.length === 0)
            return newArray;

        return Array.from(new Set(product.skuList.map(item => item.size)));
    }

    function renderColor() {
        var newArray: any[] = [];
        if (!product.skuList && product.skuList.length === 0)
            return newArray;

        const colors = Array.from(new Set(product.skuList.map(item => item.color)));
        colors.forEach(color => {
            newArray.push({ color: color, code: product.skuList.find(x => x.color === color).colorCode });
        });

        return newArray;
    }

    const renderImage = () => {
        var newArray: string[] = [];
        return product.skuList &&
            product.skuList.map(x => {
                if (!newArray.includes(x.color)) {
                    newArray.push(x.color);

                    return <img
                        key={x.id}
                        src={x.urlImg}
                        alt="image-product"
                        className=""
                    // onMouseEnter={() => setState()}
                    // style={{ display: x.color === selectedSku.color ? "block" : "none" }}
                    />
                }
            });
    }

    function reviewTotalCount() {
        return product ? product.reviewList.length : 0;
    }

    function reviewCountByEvaluation(evaluation: number) {
        return product !== null ? product.reviewList.filter(x => x.evaluation === evaluation).length : 0;
    }

    function reviewMediateValue() {
        var valueTotalWithLevel = 0;
        if (product === null || product.reviewList.length < 1)
            return 0;

        product.reviewList.map(x => valueTotalWithLevel = valueTotalWithLevel + x.evaluation);
        return valueTotalWithLevel / reviewTotalCount();
    }

    function getFormattedDate(strDate: Date) {
        const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
        let data = new Date(strDate);
        let dataFormatada = ((data.getDate() + " " + meses[(data.getMonth())] + " " + data.getFullYear()));
        return dataFormatada;
    }

    function setModalState(state: boolean) {
        setShowModal(state);
        props.setNoScroll(state);
    }

    function calculateInstallment(price: number) {
        const due = (price * 13.65) / 100; // juros para 6x
        return (Number(price) + Number(due)) / 6;
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        autoplaySpeed: 2000,
        arrows: false
    };

    const btnColapseHandlerList = document.querySelectorAll(".colapse-handler");
    const colapseContentList = document.querySelectorAll(".colapse-content");

    const handleColapse = (event: MouseEvent) => {
        const target = event.currentTarget;

        btnColapseHandlerList.forEach(x => x.classList.remove("active"));
        colapseContentList.forEach(x => x.classList.remove("active"));
        target.classList.add("active");

        var colapseContent = document.querySelector(`.colapse-content[title='${target.getAttribute("title")}']`);
        colapseContent.classList.add("active");
    }

    async function evaluate(comment: string, evaluation: number) {
        props.showLoading(true);
        const createReviewRequest = {
            productId: product.id,
            comment: comment,
            evaluation: evaluation
        } as CreateReviewRequest;
        const result = await ProductService.getInstance().createReview(createReviewRequest);
        if (!result.success) {
            props.showLoading(false);
            sharedContext.alert(false, "Não foi possível avaliar o produto no momento, tente novamente em alguns instantes");
            return;
        }

        const { productUrl } = props;
        var getProductResult = await ProductService.getInstance().getByUrl(productUrl);
        props.showLoading(false);
        if (!getProductResult.success) {
            setProduct(null);
            return;
        }

        setProduct(getProductResult.data);
        sharedContext.alert(true, "Avaliação realizada com sucesso!");
    }

    return (
        <>
            {product !== null ? <>
                <section className="product-details">
                    <div className="product-details-column">
                        <div className="product-image">
                            <div className="slider-buttons">
                                <button className="slider-prev-btn" onClick={() => myRef.current.slickNext()}>
                                    <i className="material-icons-outlined">chevron_right</i>
                                </button>
                                <button className="slider-next-btn" onClick={() => myRef.current.slickPrev()}>
                                    <i className="material-icons-outlined">chevron_left</i>
                                </button>
                            </div>
                            <Slider {...settings} ref={myRef}>
                                {renderImage()}
                            </Slider>
                        </div>
                    </div>
                    <div className="product-details-column">
                        <div className="product-details-info">
                            <span className="details-info-category">{product.category.title}</span>
                            <span className="details-info-title">{product.title}</span>
                            <span className="details-info-price">{Price(product.price)}</span>
                            <span className="details-info-condition">ou 6x de {Price(calculateInstallment(product.price))} no cartão</span>
                            <div className="product-options">
                                <div className="input-size">
                                    <span>Tamanho: {selectedSku.size}</span>
                                    <ul>
                                        {renderSize().map((x) => (
                                            <li
                                                className={x === selectedSku.size ? "active" : ""}
                                                key={x}
                                                onClick={() => selectSize(x)}
                                            >{x}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="input-color">
                                    <span>Cor: {selectedSku.color}</span>
                                    <ul>
                                        {renderColor().map((x, i) => (
                                            <li
                                                className={x.color === selectedSku.color ? "active" : ""}
                                                style={{
                                                    background: x.code, // background: linear-gradient(80deg, #ff0000 50%, #0000ff 50%); for two colors
                                                    border: `2px solid ${x.color === selectedSku.color ? "#000" : "#ffffff"}`
                                                }}
                                                key={x.color}
                                                onClick={() => selectColor(x.color, i)}
                                            ></li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="check-size">
                                    <a onClick={() => setModalState(true)}>
                                        <img src="/assets/icons/icon-size.svg" alt="icon-size" />
                                        <span>Consulte suas medidas: </span>
                                    </a>
                                </div>
                            </div>
                            <button type="button" className="btn btn-send-cart" onClick={() => addToCart()}>Adicionar no carrinho</button>
                        </div>
                    </div>
                    <div className="product-details-column colapses">
                        <div className="product-details-header">
                            <div className="colapse-handler active" title="description" onClick={(e) => handleColapse(e)}>
                                Descrição
                                <hr />
                            </div>
                            <div className="colapse-handler" title="details" onClick={(e) => handleColapse(e)}>
                                Detalhes
                                <hr />
                            </div>
                            <div className="colapse-handler" title="reviews" onClick={(e) => handleColapse(e)}>
                                Avaliação
                                <hr />
                            </div>
                        </div>
                        <div className="product-details-content">
                            <div className="colapse-content active" title="description">
                                <div className="colapse-text">
                                    <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
                                </div>
                            </div>
                            <div className="colapse-content" title="details">
                                <div className="colapse-text">
                                    <div dangerouslySetInnerHTML={{ __html: product.details }}></div>
                                </div>
                            </div>
                            <div className="colapse-content" title="reviews">
                                <div className="review-title text-icon">
                                    {product.reviewList && product.reviewList.length > 0 ?
                                        <>
                                            <span>{reviewMediateValue().toFixed(1)}</span>
                                            <i className={`star ${reviewMediateValue() >= 1 ? "active" : ""} material-icons-outlined`}>star</i>
                                            <i className={`star ${reviewMediateValue() >= 2 ? "active" : ""} material-icons-outlined`}>star</i>
                                            <i className={`star ${reviewMediateValue() >= 3 ? "active" : ""} material-icons-outlined`}>star</i>
                                            <i className={`star ${reviewMediateValue() >= 4 ? "active" : ""} material-icons-outlined`}>star</i>
                                            <i className={`star ${reviewMediateValue() >= 5 ? "active" : ""} material-icons-outlined`}>star</i>
                                        </>
                                        :
                                        <p>Este produto ainda não foi avaliado</p>
                                    }
                                </div>
                                <div className="review-items">
                                    {product.reviewList.map(x => (
                                        <div className="review-item" key={x.id}>
                                            <div className="rate">
                                                <span className="text-icon">
                                                    {x.name}
                                                    <div className="stars" style={{ display: "flex" }}>
                                                        <i className={`star ${x.evaluation >= 1 ? "active" : ""} material-icons-outlined`}>star</i>
                                                        <i className={`star ${x.evaluation >= 2 ? "active" : ""} material-icons-outlined`}>star</i>
                                                        <i className={`star ${x.evaluation >= 3 ? "active" : ""} material-icons-outlined`}>star</i>
                                                        <i className={`star ${x.evaluation >= 4 ? "active" : ""} material-icons-outlined`}>star</i>
                                                        <i className={`star ${x.evaluation >= 5 ? "active" : ""} material-icons-outlined`}>star</i>
                                                    </div>
                                                </span>
                                                <span className="rate-date">
                                                    {getFormattedDate(x.createdAt)}
                                                </span>
                                            </div>
                                            <div className="comment">
                                                {x.comment}
                                            </div>
                                        </div>
                                    ))}
                                    {canEvaluate && product.reviewList.filter(x => x.email === userContext.user.email).length === 0 &&
                                        <ReviewForm
                                            evaluate={(comment: string, evaluation: number) => evaluate(comment, evaluation)}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="related-products-title">
                        <h3>VOCÊ TAMBÉM VAI GOSTAR DE: </h3>
                    </div>
                    <div className="related-products">
                        {relatedProducts.map(x => <Product key={x.id} product={x} />)}
                    </div>
                </section>
            </>
                :
                <div className="list-empty">
                    <div className="product-list-empty-info">
                        <span>
                            Produto não encontrado
                        </span>
                        <i className="material-icons-outlined">search</i>
                    </div>
                </div>
            }
            <CheckSize
                showModal={showModal}
                setShowModal={value => setModalState(value)}
            />
        </>
    );
}