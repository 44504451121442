import { CreateReviewRequest, FilterProductsResponse, createProductRequest } from '../contracts/ProductContracts';
import { BaseResponse } from '../entities/BaseResponse';
import { ProductEntity } from '../entities/ProductEntity';
import { AppSettings } from '../settings/AppSettings';
import { BaseService } from './BaseService';

export class ProductService extends BaseService {
    static myInstance: ProductService = null;
    
    static getInstance(){
        if(ProductService.myInstance === null){
            ProductService.myInstance = new ProductService();
        }

        return this.myInstance;
    }

    async getAllProducts(query: string): Promise<BaseResponse<FilterProductsResponse>> {
        return await super.get("/product/filterproducts" + query);
    }

    async getLastCreated(): Promise<BaseResponse<ProductEntity[]>> {
        return await super.get(`/product/filterproducts?limit=${AppSettings.LastProductsLimit}&order=newest`);
    }

    async getTopSelling(): Promise<BaseResponse<ProductEntity[]>> {
        const limit = AppSettings.TopSellingProductsLimit;
        return await super.get(`/product/filterproducts?limit=${AppSettings.TopSellingProductsLimit}&order=best-seller`);
    }

    async getAllByProductName(productName: string): Promise<BaseResponse<ProductEntity[]>> {
        productName = "%" + productName.replace(" ", "%") + "%";

        return await super.get("/product/getproductbyname?productName=" + productName);
    }

    async getByUrl(url: string): Promise<BaseResponse<ProductEntity>> {
        return await super.get("/product/getproductbyurl/" + url);
    }

    async getByCategoryAndBrand(category: string = null, brand: string = null): Promise<BaseResponse<ProductEntity[]>> {
        return await super.get("/product/getproductbycategoryandbrand?categoryUrl=" + category + "&brandUrl=" + brand);
    }

    async createProduct(request: createProductRequest): Promise<BaseResponse<any>> {
        return await super.post("/product/createproduct", request, true);
    }

    async isProductBoughtByUser(productId: number): Promise<BaseResponse<any>> {
        return await super.get(`/product/isproductboughtbyuser/${productId}`, true);
    }

    async createReview(request: CreateReviewRequest): Promise<BaseResponse<any>> {
        return await super.post("/product/createreview", request, true);
    }
}
