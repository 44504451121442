import { useContext, useEffect, useState } from 'react';
import InputMask from "react-input-mask";
import { UserService } from '../../services/UserService';
import { Link } from 'react-router-dom';
import { SharedContext } from '../../contexts/SharedContext';
import { UserContext } from '../../contexts/UserContext';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ptBR } from 'date-fns/locale/pt-BR';
registerLocale('ptBR', ptBR);

interface Props {
    showLoading(value: boolean): void;
}

export function Signup(props: Props) {
    const sharedContext = useContext(SharedContext);
    const userContext = useContext(UserContext);

    const [showValidFields, setShowValidFields] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [name, setName] = useState('');
    const [document, setDocument] = useState('');
    const [email, setEmail] = useState('');
    const [birthdayAt, setBirthdayAt] = useState<Date>(null);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isPasswordDifferent, setIsPasswordDifferent] = useState(false);

    useEffect(() => {
        // const bodyDocument = document.querySelector("body");
        // bodyDocument.classList = [];
    }, []);

    async function trySignup() {
        setShowValidFields(true);
        setIsPasswordDifferent(false);
        if (name === '' || document === '' || email === '' ||
            phone === '' || password === '' || passwordConfirmation === '') {
            sharedContext.alert(false, "Preencha todos os campos para fazer o cadastro.");
            return;
        }

        if (password !== passwordConfirmation) {
            setIsPasswordDifferent(true);
            sharedContext.alert(false, "As senhas digitadas não coincidem.");
            return;
        }

        props.showLoading(true);
        var user = {
            name: name,
            document: document,
            email: email,
            birthdayAt: birthdayAt.toJSON(),
            phone: phone,
            password: password
        };
        var signupResponse = await UserService.getInstance().createUser(user);
        if (!signupResponse.success) {
            sharedContext.alert(false, signupResponse.errors[0].message);
            props.showLoading(false);
            return;
        }

        userContext.handleToken(signupResponse.data.token, signupResponse.data.refreshToken);

        sharedContext.alert(true, "Usuário criado com sucesso!");
        // this.setState({ logged: true });

        const myTimeout = setTimeout(() => {
            window.location.href = "/";
            props.showLoading(false);

            clearTimeout(myTimeout);
        }, 2000);
    }

    return (
        <section className="form-container">
            <div className="form">
                <form className="form-box break" action="">
                    <div className="form-content">
                        <div className="logo-box">
                            <div className="logo">
                                <a href="/">
                                    <img src="/assets/images/favicon.png" width="35" height="35" alt="" />
                                    <h1>Cangaço Store</h1>
                                </a>
                            </div>
                        </div>
                        <div className="form-input">
                            <span>Nome</span>
                            <div className="input-box">
                                <input
                                    className={`input-field light ${showValidFields && name === '' ? 'invalid' : ''}`}
                                    type="text"
                                    placeholder="Seu nome completo"
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <span>E-mail</span>
                            <div className="input-box">
                                <input
                                    className={`input-field light ${showValidFields && email === '' ? 'invalid' : ''}`}
                                    type="email"
                                    placeholder="Seu e-mail"
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            {/* <span>CPF/CNPJ</span> */}
                            <span>CPF</span>
                            <div className="input-box">
                                <InputMask
                                    className={`input-field light ${showValidFields && document === '' ? 'invalid' : ''}`}
                                    type="text"
                                    // placeholder="Seu CPF ou CNPJ" 
                                    placeholder="Seu CPF"
                                    mask="999.999.999-99"
                                    onChange={e => setDocument(e.target.value.replace(/[^a-z0-9]/gi, ''))}
                                    value={document}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <span>Data de nascimento</span>
                            <div className="input-box">
                                <DatePicker
                                    showIcon
                                    className={`input-field light ${showValidFields && birthdayAt === null ? 'invalid' : ''}`}
                                    selected={birthdayAt}
                                    onChange={(date: Date) => setBirthdayAt(date)}
                                    placeholderText='Data de nascimento'
                                    dateFormat="dd/MM/yyyy"
                                    locale="ptBR"
                                    icon={<i className="material-icons-outlined">calendar_month</i>}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <span>Celular</span>
                            <div className="input-box">
                                <InputMask
                                    className={`input-field light ${showValidFields && phone === '' ? 'invalid' : ''}`}
                                    type="text"
                                    placeholder="Digite seu número"
                                    mask="(99) 9 9999-9999"
                                    onChange={e => setPhone(e.target.value.replace(/[^a-z0-9]/gi, ''))}
                                    value={phone}
                                />
                            </div>
                        </div>
                        <div className="form-input">
                            <span>Senha</span>
                            <div className="input-box">
                                <input
                                    className={`input-field light ${isPasswordDifferent || (showValidFields && password === '') ? 'invalid' : ''}`}
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Senha"
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                />
                                <button className="input-button btn light" type="button" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <i className="material-icons-outlined">visibility_on</i> : <i className="material-icons-outlined">visibility_off</i>}
                                </button>
                            </div>
                            <div className="input-box">
                                <input
                                    className={`input-field light ${isPasswordDifferent || (showValidFields && passwordConfirmation === '') ? 'invalid' : ''}`}
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Confirmar senha"
                                    onChange={e => setPasswordConfirmation(e.target.value)}
                                    value={passwordConfirmation}
                                />
                            </div>
                            <p>Ao criar sua conta, você concorda com os nossos Termos de uso e com a Política de privacidade.</p>
                        </div>
                        <div className="form-input">
                            <button className="btn btn-form" type="button" onClick={() => trySignup()}>Cadastrar</button>
                        </div>
                        <div className="form-links">
                            <Link to={"/login"}>Já tem uma conta? Login.</Link>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}
